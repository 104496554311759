import { useState, useEffect } from 'react';
import { XMarkIcon, StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { submitFeedback } from '../services/feedback';

const STORAGE_KEYS = {
  LAST_SHOWN: 'feedback_last_shown',
  LAST_SUBMITTED: 'feedback_last_submitted',
  FIRST_VISIT: 'feedback_first_visit',
};

const COOLDOWN_PERIODS = {
  SHOWN: 24 * 60 * 60 * 1000, // 24 hours
  SUBMITTED: 7 * 24 * 60 * 60 * 1000, // 7 days
  MIN_DELAY: 7 * 1000, // 7 seconds minimum before showing
  MIN_INTERACTIONS: 3, // Minimum number of interactions required
};

export const FeedbackForm = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [interactionCount, setInteractionCount] = useState(0);
  const [lastInteractionTime, setLastInteractionTime] = useState(0);
  const [isInteracting, setIsInteracting] = useState(false);
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);

  const starDescriptions = {
    1: "Horrible",
    2: "Very bad",
    3: "Average at best",
    4: "Great",
    5: "Amazing"
  };

  // Track user interactions for showing the form initially
  useEffect(() => {
    const handleInteraction = () => {
      const now = Date.now();
      // Only count new interactions if they're at least 500ms apart
      if (now - lastInteractionTime > 500) {
        setInteractionCount(prev => prev + 1);
        setLastInteractionTime(now);
      }
    };

    // Track clicks and key presses only
    const handleClick = (e: MouseEvent) => {
      // Don't count clicks inside the feedback form
      const target = e.target as HTMLElement;
      if (target.closest('.feedback-form')) {
        setIsInteracting(true);
        return;
      }
      
      // Only track interactions for showing the form initially
      // Don't close the form when clicking outside
      if (!isVisible) {
        handleInteraction();
      }
    };

    const handleKeydown = (e: KeyboardEvent) => {
      // Don't count key presses when typing in the textarea
      const target = e.target as HTMLElement;
      if (target.closest('.feedback-form textarea')) {
        setIsInteracting(true);
        return;
      }
      
      // Only track interactions for showing the form initially
      if (!isVisible) {
        handleInteraction();
      }
    };

    window.addEventListener('click', handleClick);
    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [lastInteractionTime, isVisible]);

  // Reset interaction state after a delay
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isInteracting) {
      timeoutId = setTimeout(() => {
        setIsInteracting(false);
      }, 1000); // Reset after 1 second of no interaction
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isInteracting]);

  useEffect(() => {
    const checkShouldShow = () => {
      const lastShown = localStorage.getItem(STORAGE_KEYS.LAST_SHOWN);
      const lastSubmitted = localStorage.getItem(STORAGE_KEYS.LAST_SUBMITTED);
      const firstVisit = localStorage.getItem(STORAGE_KEYS.FIRST_VISIT);

      // Set first visit time if not set
      if (!firstVisit) {
        localStorage.setItem(STORAGE_KEYS.FIRST_VISIT, Date.now().toString());
        return false;
      }

      const timeSinceFirstVisit = Date.now() - parseInt(firstVisit);
      const shouldShow = timeSinceFirstVisit >= COOLDOWN_PERIODS.MIN_DELAY && 
                        interactionCount >= COOLDOWN_PERIODS.MIN_INTERACTIONS;

      if (!shouldShow) {
        return false;
      }

      if (lastSubmitted) {
        const submittedTime = parseInt(lastSubmitted);
        if (Date.now() - submittedTime < COOLDOWN_PERIODS.SUBMITTED) {
          return false;
        }
      }

      if (lastShown) {
        const shownTime = parseInt(lastShown);
        if (Date.now() - shownTime < COOLDOWN_PERIODS.SHOWN) {
          return false;
        }
      }

      return true;
    };

    const shouldShow = checkShouldShow();
    setIsVisible(shouldShow);
  }, [interactionCount]); // Re-run when interaction count changes

  // Add a new useEffect to handle setting lastShown after the form is visible
  useEffect(() => {
    if (isVisible) {
      localStorage.setItem(STORAGE_KEYS.LAST_SHOWN, Date.now().toString());
    }
  }, [isVisible]);

  const handleClose = () => {
    // Only close when explicitly called by close buttons
    setIsExiting(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 300); // Match the animation duration
  };

  const handleSubmit = async () => {
    if (!rating) return;

    setIsSubmitting(true);
    const success = await submitFeedback({
      rating,
      comment,
      timestamp: new Date().toISOString(),
      url: window.location.href,
    });

    if (success) {
      setIsSubmitted(true);
      localStorage.setItem(STORAGE_KEYS.LAST_SUBMITTED, Date.now().toString());
    }
    setIsSubmitting(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div 
      className={`fixed bottom-4 left-4 right-4 sm:left-auto sm:right-4 z-[9999] w-[calc(100%-2rem)] sm:w-[400px] bg-zinc-800 rounded-lg shadow-xl border border-zinc-700 feedback-form
        transform transition-all duration-300 ease-out
        ${isExiting ? 'translate-y-full opacity-0' : 'translate-y-0 opacity-100'}`}
      onClick={(e) => {
        // Prevent clicks on the form from propagating to the document
        e.stopPropagation();
      }}
    >
      <div className="p-4 flex flex-col">
        <div className="flex justify-between items-start mb-6">
          <h3 className="text-lg font-semibold text-white">How are we doing?</h3>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            className="text-zinc-400 hover:text-zinc-300 transition-colors p-2 -m-2"
          >
            <XMarkIcon className="h-6 w-6 transition-transform duration-200 hover:rotate-90" />
          </button>
        </div>

        {!isSubmitted ? (
          <>
            <div className="flex justify-between items-center mb-6 px-2 relative">
              {[1, 2, 3, 4, 5].map((star) => (
                <div key={star} className="relative">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsInteracting(true);
                      setRating(star);
                    }}
                    onMouseEnter={() => {
                      setIsInteracting(true);
                      setHoverRating(star);
                      setHoveredStar(star);
                    }}
                    onMouseLeave={() => {
                      setHoverRating(0);
                      setHoveredStar(null);
                    }}
                    className="text-zinc-400 hover:text-yellow-400 transition-all duration-200 transform hover:scale-110 p-2 -m-2"
                  >
                    {star <= (hoverRating || rating) ? (
                      <StarIconSolid className="h-8 w-8 text-yellow-400 animate-pulse" />
                    ) : (
                      <StarIcon className="h-8 w-8" />
                    )}
                  </button>
                  <div 
                    className={`absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-1.5 bg-zinc-700 text-white text-sm rounded-lg whitespace-nowrap
                      transition-all duration-200 transform origin-bottom
                      ${hoveredStar === star 
                        ? 'opacity-100 translate-y-0 scale-100' 
                        : 'opacity-0 translate-y-2 scale-95 pointer-events-none'}`}
                  >
                    {starDescriptions[star as keyof typeof starDescriptions]}
                    <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-zinc-700"></div>
                  </div>
                </div>
              ))}
            </div>

            <textarea
              value={comment}
              onChange={(e) => {
                e.stopPropagation();
                setIsInteracting(true);
                setComment(e.target.value);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsInteracting(true);
              }}
              placeholder="Tell us more (optional)"
              className="w-full bg-zinc-700 text-white rounded-lg px-4 py-3 mb-6 resize-none h-32 
                focus:outline-none focus:ring-2 focus:ring-rust-orange transition-all duration-200
                hover:bg-zinc-600 text-base"
            />

            <button
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit();
              }}
              disabled={!rating || isSubmitting}
              className="w-full bg-rust-orange text-white rounded-lg px-4 py-3 font-medium text-base
                hover:bg-rust-orange/90 transition-all duration-200 disabled:opacity-50 
                disabled:cursor-not-allowed transform hover:scale-[1.02] active:scale-[0.98]
                disabled:hover:scale-100 disabled:active:scale-100"
            >
              {isSubmitting ? (
                <span className="flex items-center justify-center gap-2">
                  <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  Submitting...
                </span>
              ) : (
                'Submit Feedback'
              )}
            </button>
          </>
        ) : (
          <div className="text-center py-8 animate-fade-in">
            <div className="mb-6">
              <svg className="mx-auto h-16 w-16 text-green-500 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <p className="text-white mb-4 text-xl font-medium">Thank you for your feedback!</p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
              className="text-zinc-400 hover:text-zinc-300 transition-colors hover:scale-105 duration-200 text-lg p-2 -m-2"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
}; 