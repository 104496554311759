export const AboutSection = () => (
  <section id="about" className="py-16 sm:py-20 px-4">
    <div className="max-w-7xl mx-auto">
      <h2 className="section-title">About Rust4Noobs</h2>
      <p className="text-xl text-zinc-300 text-center mb-12 max-w-3xl mx-auto">
        Your Gateway to Rust Gaming
      </p>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 items-start">
        {/* Left Column - Text Content */}
        <div className="space-y-8">
          {/* Server Types Card */}
          <div className="card">
            <h3 className="text-2xl font-bold text-white mb-4">Diverse Server Options</h3>
            <div className="space-y-4">
              <p className="text-zinc-300">
                Choose from our carefully curated selection of servers:
              </p>
              <ul className="space-y-2 text-zinc-300">
                <li className="flex items-center gap-2">
                  <span className="w-2 h-2 bg-rust-orange rounded-full"></span>
                  PvE servers for peaceful gameplay
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-2 h-2 bg-rust-orange rounded-full"></span>
                  Solo, Duo, and Squad options
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-2 h-2 bg-rust-orange rounded-full"></span>
                  Vanilla and 2x gather rates
                </li>
              </ul>
            </div>
          </div>

          {/* Upkeep Options Card */}
          <div className="card">
            <h3 className="text-2xl font-bold text-white mb-4">Flexible Upkeep Options</h3>
            <p className="text-zinc-300">
              Our servers feature various upkeep options (vanilla, 50%, and 25%), making base maintenance easier for newcomers. Whether you're looking for a peaceful PvE experience or want to try your hand at PvP, our carefully balanced servers provide the perfect starting point for your Rust journey.
            </p>
          </div>

          {/* Community Card */}
          <div className="card">
            <h3 className="text-2xl font-bold text-white mb-4">Expert Support & Active Community</h3>
            <p className="text-zinc-300">
              What sets Rust4Noobs apart is our dedicated team of experienced admins and moderators, always ready to help new players learn the ropes. Join our thriving community of friendly players who share your passion for Rust and are eager to help you succeed.
            </p>
          </div>
        </div>

        {/* Right Column - Video (Hidden on mobile) */}
        <div className="relative hidden lg:block">
          <div className="aspect-video rounded-lg overflow-hidden shadow-xl">
            <iframe
              src="https://www.youtube-nocookie.com/embed/LGcECozNXEw?rel=0"
              title="Learn to play Rust on the best beginner-friendly servers"
              className="w-full h-full"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div className="absolute -bottom-4 -right-4 w-24 h-24 bg-rust-orange/10 rounded-full blur-2xl"></div>
        </div>
      </div>
    </div>
  </section>
); 