import { FC } from 'react';
import { Button } from './ui/Button';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { track } from '@vercel/analytics';
import { useAuth } from '../contexts/AuthContext';
import { siteConfig } from '../config/site.config';

interface HeroSectionProps {
  onStoreClick: () => void;
  onViewServersClick: () => void;
  onScrollToAbout: () => void;
}

export const HeroSection: FC<HeroSectionProps> = ({ onStoreClick, onViewServersClick, onScrollToAbout }) => {
  const { user, isAuthenticated } = useAuth();

  const handleStoreClick = () => {
    // Track the support us button click
    track('support_us_click', {
      steamId: isAuthenticated && user ? user.steamid : 'not_logged_in'
    });

    // Call the original onStoreClick handler
    onStoreClick();
  };

  return (
    <section className="relative min-h-screen flex flex-col items-center justify-center text-center px-4 sm:px-6">
      {/* Background image with overlay */}
      <div className="absolute inset-0 bg-[url('/images/optimized/hero-bg.webp')] [&:not(:has(picture))]:bg-[url('/images/hero-bg.png')] bg-cover bg-center" />
      <div className="absolute inset-0 bg-gradient-to-b from-zinc-900/90 via-zinc-900/80 to-zinc-900" />

      {/* Content */}
      <div className="relative z-10 max-w-4xl mx-auto mb-8 sm:mb-16">
        {/* Logo/Icon */}
        <div className="mb-6 sm:mb-8">
          <img
            src="https://cdn.4noobs.co/R4N/Logo/logo-1.png"
            alt="Rust4Noobs Logo"
            className="w-32 h-24 sm:w-48 sm:h-32 mx-auto mb-3 sm:mb-4 drop-shadow-[0_0_15px_rgba(0,0,0,0.7)] object-contain"
            width="192"
            height="128"
          />
        </div>

        <h1 className="text-3xl sm:text-5xl md:text-7xl font-display font-bold mb-4 sm:mb-6 text-white drop-shadow-lg leading-tight">
          Welcome to {siteConfig.name}
        </h1>
        <p className="text-lg sm:text-xl md:text-2xl text-zinc-300 mb-6 sm:mb-8 drop-shadow max-w-2xl mx-auto">
          {siteConfig.description}
        </p>
        <div className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-4 px-4 sm:px-0">
          <Button
            size="lg"
            onClick={handleStoreClick}
            className="animate-pulse-subtle hover:animate-none w-full sm:w-auto"
          >
            Support Us & Get Perks
          </Button>
          <Button
          variant="outline"
          size="lg"
          onClick={onViewServersClick}
          className="w-full sm:w-auto"
        >
            View Servers
          </Button>
        </div>
      </div>

      {/* Scroll indicator */}
      <div className="relative z-10 mt-8 sm:mt-0">
        <button
          onClick={onScrollToAbout}
          className="animate-bounce cursor-pointer bg-transparent border-none p-0"
          aria-label="Scroll to about"
        >
          <div className="w-12 h-12 sm:w-10 sm:h-10 border-2 border-white/30 rounded-full flex items-center justify-center">
            <ChevronDownIcon className="w-7 h-7 sm:w-6 sm:h-6 text-white/30" />
          </div>
        </button>
      </div>
    </section>
  );
};
