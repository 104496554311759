import { useState, useEffect } from 'react';
import { 
  UsersIcon, 
  ClockIcon, 
  MapPinIcon, 
  ChartBarIcon, 
  TrophyIcon, 
  ArrowPathIcon, 
  DocumentDuplicateIcon,
  LightBulbIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/24/outline';
import {
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts';
import type { ServerInfo } from '../config/site.config';
import { Button } from './ui/Button';
import { Tooltip } from './ui/Tooltip';
import { getServerData } from '../services/battlemetrics';
import { 
  format, 
  parseISO, 
  getMonth,
  getYear,
  startOfMonth,
  getDay
} from 'date-fns';
import { track } from '@vercel/analytics';
import { useAuth } from '../contexts/AuthContext';

// Custom hook for responsive design
const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);

    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
};

interface EnhancedServerCardProps {
  server: ServerInfo;
}

interface PlayerHistoryPoint {
  timestamp: string;
  value: number;
}

interface ExtendedServerData {
  players: number;
  maxPlayers: number;
  status: string;
  rank: number;
  sevenDayAvg: number;
  fourteenDayAvg: number;
  playerHistory?: PlayerHistoryPoint[];
  details?: {
    mapSize: string;
    groupLimit: string;
    gatherRate: string;
    lastWipe: string;
    nextWipe: string;
    isModded: boolean;
    description: string | null;
    mapInfo?: {
      biomePercentages: Record<string, number>;
    };
    settings?: {
      upkeep: number;
      rates: {
        gather: number;
        component: number;
        craft: number;
        scrap: number;
      };
      groupLimit: number;
    };
  };
}

/**
 * Calculate the first Thursday of a given month
 * @param year Year
 * @param month Month (0-indexed, 0 = January, 11 = December)
 * @returns Date object for the first Thursday of that month
 */
const getFirstThursday = (year: number, month: number): Date => {
  // Start at the beginning of the month
  const firstDayOfMonth = startOfMonth(new Date(year, month));
  
  // Get the day of the week for the first day (0 = Sunday, 4 = Thursday)
  const firstDayOfWeek = getDay(firstDayOfMonth);
  
  // Calculate how many days to add to get to the first Thursday
  // If the first day is already Thursday (4), add 0
  // If the first day is after Thursday, add (7 - firstDayOfWeek + 4) to get to next week's Thursday
  // If the first day is before Thursday, add (4 - firstDayOfWeek) to get to this week's Thursday
  let daysToAdd = 0;
  if (firstDayOfWeek <= 4) {
    // First day is Sunday through Thursday, so Thursday is in the same week
    daysToAdd = 4 - firstDayOfWeek;
  } else {
    // First day is Friday or Saturday, so Thursday is in the next week
    daysToAdd = 4 + (7 - firstDayOfWeek);
  }
  
  // Create a new date by adding the necessary days
  const firstThursday = new Date(year, month, 1 + daysToAdd);
  return firstThursday;
};

/**
 * Get the last wipe date (first Thursday of the previous month)
 * @returns Date object for the last wipe
 */
const getLastWipeDate = (): Date => {
  const today = new Date();
  
  // Get the current date information
  const currentMonth = getMonth(today);
  const currentYear = getYear(today);
  
  // Calculate the previous month and year
  let previousMonth = currentMonth - 1;
  let previousYear = currentYear;
  
  // Handle January case (previous month would be December of last year)
  if (previousMonth < 0) {
    previousMonth = 11; // December
    previousYear -= 1;
  }
  
  // Calculate the first Thursday of the previous month
  return getFirstThursday(previousYear, previousMonth);
};

/**
 * Get the next wipe date (first Thursday of the next month)
 * @returns Date object for the next wipe
 */
const getNextWipeDate = (): Date => {
  const today = new Date();
  
  // Get the current date information
  const currentMonth = getMonth(today);
  const currentYear = getYear(today);
  
  // Calculate the next month and year
  let nextMonth = currentMonth + 1;
  let nextYear = currentYear;
  
  // Handle December case (next month would be January of next year)
  if (nextMonth > 11) {
    nextMonth = 0; // January
    nextYear += 1;
  }
  
  // Calculate the first Thursday of the next month
  return getFirstThursday(nextYear, nextMonth);
};

/**
 * Skeleton component for loading state
 */
const SkeletonLoader = () => (
  <div className="animate-pulse">
    {/* Header */}
    <div className="mb-4">
      <div className="h-8 bg-zinc-700 rounded-md w-3/4 mb-2"></div>
      <div className="h-4 bg-zinc-700 rounded-md w-full"></div>
    </div>
    
    {/* Status bar */}
    <div className="h-12 bg-zinc-700 rounded-lg mb-4"></div>
    
    {/* Stats grid */}
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-4">
      <div className="h-28 bg-zinc-700 rounded-lg"></div>
      <div className="h-28 bg-zinc-700 rounded-lg"></div>
      <div className="h-28 bg-zinc-700 rounded-lg"></div>
      <div className="h-28 bg-zinc-700 rounded-lg"></div>
    </div>
    
    {/* Tags */}
    <div className="flex flex-wrap gap-2 mb-4">
      <div className="h-6 bg-zinc-700 rounded-full w-16"></div>
      <div className="h-6 bg-zinc-700 rounded-full w-20"></div>
      <div className="h-6 bg-zinc-700 rounded-full w-24"></div>
      <div className="h-6 bg-zinc-700 rounded-full w-16"></div>
    </div>
    
    {/* Button */}
    <div className="h-10 bg-zinc-700 rounded-md w-full"></div>
  </div>
);

/**
 * Helper function to extract group limit information
 * @param features Array of server features
 * @param settings Server settings from API
 * @returns Group limit as a string
 */
const getGroupLimit = (features: string[], settings?: { groupLimit?: number }): string => {
  // Check if groupLimit is available from API settings
  if (settings?.groupLimit) {
    const limit = settings.groupLimit;
    // Treat any limit above 10 as "No Limit"
    if (limit > 10) {
      return "No Limit";
    }
    switch (limit) {
      case 1: return "Solo";
      case 2: return "Duo";
      case 3: return "Trio";
      case 4: return "Quad";
      default: return `${limit} Players`;
    }
  }
  
  // Look for group indicators in features as fallback
  const soloRegex = /\bsolo\b/i;
  const duoRegex = /\bsolo\/duo\b|\bduo\b/i;
  const trioRegex = /\btrio\b|\bsolo\/duo\/trio\b/i;
  const quadRegex = /\bquad\b|\bsolo-quad\b/i;
  
  for (const feature of features) {
    if (feature.match(/\ball groups\b/i)) {
      return "No Limit";
    }
    
    if (feature.match(quadRegex)) {
      return "Quad";
    }
    
    if (feature.match(trioRegex)) {
      return "Trio";
    }
    
    if (feature.match(duoRegex)) {
      return "Duo";
    }
    
    if (feature.match(soloRegex)) {
      return "Solo";
    }
  }
  
  return "Unknown";
};

/**
 * Checks if a server is using official wipe schedule (first Thursday of month)
 * @param features Server features array
 * @returns Boolean indicating if this is a monthly wipe server
 */
const isMonthlyWipeServer = (features: string[]): boolean => {
  return features.some(feature => 
    /monthly\s*wipe/i.test(feature)
  );
};

const PlayerCountChart = ({ data }: { data: PlayerHistoryPoint[] }) => {
  return (
    <ResponsiveContainer width="100%" height={40}>
      <AreaChart
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="playerCountGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="rgb(234, 88, 12)" stopOpacity={0.3} />
            <stop offset="100%" stopColor="rgb(234, 88, 12)" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          stroke="rgb(234, 88, 12)"
          strokeWidth={1}
          fill="url(#playerCountGradient)"
          isAnimationActive={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

/**
 * An enhanced card component that displays detailed information about a Rust server.
 * @param server - The server information to display
 */
export default function EnhancedServerCard({ server }: EnhancedServerCardProps) {
  const [serverData, setServerData] = useState<ExtendedServerData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [copied, setCopied] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isExpanded, setIsExpanded] = useState(false); // Start collapsed by default

  useEffect(() => {
    const fetchServerData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const data = await getServerData(server.battlemetricsId);
        
        const { 
          players, 
          status, 
          rank, 
          details
        } = data.data.attributes;
        
        const { sevenDayAvg, fourteenDayAvg } = data.playerAverages;
        
        // Get player history data from the API response with proper typing
        const playerHistory = data.playerHistory?.data.map((point: { attributes: { timestamp: string; value: number } }) => ({
          timestamp: point.attributes.timestamp,
          value: point.attributes.value
        })) || [];
        
        // Map details from API or use values from config if available
        const mappedDetails = {
          mapSize: details?.rust_world_size?.toString() || details?.rust_maps?.size?.toString() || '3500',
          groupLimit: details?.rust_settings?.groupLimit?.toString() || 'Unknown',
          gatherRate: details?.rust_settings?.rates?.gather ? `${details.rust_settings.rates.gather}x` : '1x',
          lastWipe: details?.rust_last_wipe || 'Unknown',
          nextWipe: details?.rust_next_wipe || 'Unknown',
          isModded: details?.rust_modded || false,
          description: details?.rust_description || null,
          mapInfo: details?.rust_maps ? {
            monumentCount: details.rust_maps.monumentCount,
            biomePercentages: details.rust_maps.biomePercentages,
            monuments: details.rust_maps.monuments
          } : undefined,
          settings: details?.rust_settings ? {
            upkeep: details.rust_settings.upkeep,
            rates: {
              gather: details.rust_settings.rates.gather,
              component: details.rust_settings.rates.component,
              craft: details.rust_settings.rates.craft,
              scrap: details.rust_settings.rates.scrap
            },
            groupLimit: details.rust_settings.groupLimit
          } : undefined
        };
        
        setServerData({
          players,
          maxPlayers: data.data.attributes.maxPlayers,
          status: status.toLowerCase(),
          rank,
          sevenDayAvg,
          fourteenDayAvg,
          playerHistory,
          details: mappedDetails
        });
      } catch (err) {
        console.error('Error fetching server data:', err);
        setError(err instanceof Error ? err : new Error('Failed to load server data'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchServerData();
    
    // Refresh data every 2 minutes - but only if we don't have an error
    let interval: number | undefined;
    if (!error) {
      interval = window.setInterval(fetchServerData, 120000);
    }
    
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [server.battlemetricsId, retryCount]);

  // Add a retry function for manual refresh
  const handleRetry = () => {
    setRetryCount(prev => prev + 1);
  };

  const copyIpToClipboard = () => {
    navigator.clipboard.writeText(server.ip);
    setCopied(true);

    // Track the copy event with Vercel Analytics
    track('copy_server_ip', {
      serverId: server.battlemetricsId,
      serverName: server.name,
      serverIp: server.ip,
      steamId: isAuthenticated && user ? user.steamid : 'not_logged_in'
    });

    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };
  
  const formatDate = (dateString: string) => {
    try {
      // Try to parse as ISO date first
      const date = parseISO(dateString);
      return isNaN(date.getTime()) 
        ? dateString // If not a valid date, return as is
        : format(date, 'MMM d, yyyy');
    } catch {
      // If parsing fails, return as is
      return dateString;
    }
  };
  
  // More robust approach to determining online status
  const determineServerStatus = (): boolean => {
    // If we don't have server data yet, default to false
    if (!serverData) return false;
    
    // Check the status field if available
    if (serverData.status) {
      const normalizedStatus = serverData.status.toLowerCase();
      if (normalizedStatus === 'online' || normalizedStatus === 'up') {
        return true;
      }
      
      // If status is explicitly offline/down, return false
      if (normalizedStatus === 'offline' || normalizedStatus === 'down') {
        return false;
      }
    }
    
    // Fallback check: if there are players on the server, it must be online
    if (serverData.players > 0) {
      return true;
    }
    
    // If we get to this point, we can't definitively say if the server is online
    // Default to whatever the status is, or false if we can't determine
    return serverData.status?.toLowerCase() === 'online';
  };
  
  const toggleExpand = (e: React.MouseEvent) => {
    // Don't expand if the user clicked on the copy button or its children
    if (
      (e.target as HTMLElement).closest('.copy-button') ||
      (e.target as HTMLElement).closest('.retry-button')
    ) {
      return;
    }

    // Only toggle on mobile
    if (isMobile) {
      setIsExpanded(!isExpanded);
    }
  };

  // Determine if server is online
  const isOnline = determineServerStatus();
  const displayGroupLimit = getGroupLimit(server.features, serverData?.details?.settings);
  
  // Calculate wipe dates for monthly servers
  const isMonthly = isMonthlyWipeServer(server.features);
  const lastWipeDate = isMonthly ? getLastWipeDate() : parseISO(serverData?.details?.lastWipe || '');
  const nextWipeDate = isMonthly ? getNextWipeDate() : parseISO(serverData?.details?.nextWipe || '');
  
  // Format the dates
  const formattedLastWipe = isMonthly 
    ? format(lastWipeDate, 'MMM d, yyyy') 
    : formatDate(serverData?.details?.lastWipe || 'Unknown');
    
  const formattedNextWipe = isMonthly 
    ? format(nextWipeDate, 'MMM d, yyyy')
    : formatDate(serverData?.details?.nextWipe || 'Unknown');
  
  // For display in the UI - always using formatted date instead of relative time
  const lastWipeText = formattedLastWipe;

  // Update isExpanded when screen size changes
  useEffect(() => {
    setIsExpanded(!isMobile); // Always expanded on desktop, always collapsed on mobile
  }, [isMobile]);

  return (
    <div
      className={`enhanced-server-card bg-zinc-800/70 backdrop-blur-sm border border-zinc-700 rounded-xl p-4 sm:p-5 shadow-xl relative transition-all duration-300 ${
        isExpanded ? 'ring-2 ring-rust-orange/50' : 'hover:border-zinc-600 cursor-pointer'
      }`}
      onClick={toggleExpand}
    >
      {loading ? (
        <SkeletonLoader />
      ) : error ? (
        <div className="flex flex-col items-center justify-center p-4 text-center">
          <h3 className="text-xl font-display font-bold text-rust-orange mb-3">
            {server.name}
          </h3>
          
          <div className="bg-red-900/20 border border-red-900/30 rounded-lg p-4 mb-3 w-full">
            <h3 className="text-lg font-semibold text-red-200 mb-2">Unable to Connect</h3>
            <p className="text-sm text-zinc-300 mb-4">
              We couldn't connect to the API to fetch server details.
            </p>
            <Button 
              variant="secondary" 
              size="sm" 
              onClick={(e) => {
                e.stopPropagation();
                handleRetry();
              }}
              className="mx-auto flex items-center gap-2 retry-button"
            >
              <ArrowPathIcon className="h-4 w-4" />
              Retry Connection
            </Button>
          </div>
        </div>
      ) : (
        <>
          {/* Header with Server Name, Region and Status */}
          <div className="flex items-start justify-between mb-3">
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h3 className="text-xl font-display font-bold text-rust-orange">
                  {server.name}
                </h3>
                <span className="px-2 py-0.5 bg-zinc-700 rounded-md text-xs font-medium text-zinc-100 whitespace-nowrap">
                  {server.region || 'EU'}
                </span>
                <div className={`flex items-center space-x-1 px-2 py-0.5 rounded-md ${isOnline ? 'bg-green-900/40 text-green-100' : 'bg-red-900/40 text-red-100'}`}>
                  <div className={`h-2 w-2 rounded-full ${isOnline ? 'bg-green-500 animate-pulse' : 'bg-red-500'}`} />
                  <span className="text-xs font-medium">
                    {isOnline ? 'Online' : 'Offline'}
                  </span>
                </div>
              </div>

              {/* Quick Stats Row */}
              <div className="flex flex-wrap items-center gap-3 text-sm text-zinc-300">
                <div className="flex items-center gap-1">
                  <UsersIcon className="h-4 w-4 text-zinc-400" />
                  <span>
                    {`${serverData?.players || 0}/${serverData?.maxPlayers || server.maxPlayers}`}
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <TrophyIcon className="h-4 w-4 text-zinc-400" />
                  <span>
                    Rank {serverData?.rank || 'N/A'}
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <ClockIcon className="h-4 w-4 text-zinc-400" />
                  <span>
                    Wipe: {lastWipeText}
                  </span>
                </div>
              </div>
            </div>

            {/* Expand/Collapse Indicator - Only show on mobile */}
            <div className="mt-1 text-zinc-400">
              {isMobile && (
                isExpanded ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )
              )}
            </div>
          </div>
          
          {/* Expandable Content */}
          {(!isMobile || isExpanded) && (
            <div className="pt-2 border-t border-zinc-700/50 mt-2 space-y-4 animate-fadeIn">
              {/* Server Description */}
              <p className="text-zinc-300 text-sm">
                {server.description}
              </p>

              {/* Server Stats Grid */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                {/* Average Players */}
                <div className="bg-zinc-700/30 rounded-lg p-3 relative">
                  <h4 className="text-xs uppercase text-zinc-400 font-semibold mb-2 flex items-center gap-1.5">
                    <ChartBarIcon className="h-3.5 w-3.5" />
                    Average Players
                  </h4>
                  <div className="flex gap-3 mb-3">
                    <div>
                      <span className="text-sm font-medium block">7 Days</span>
                      <span className="text-lg font-bold text-zinc-100">
                        {Math.round(serverData?.sevenDayAvg || 0)}
                      </span>
                    </div>
                    <div>
                      <span className="text-sm font-medium block">14 Days</span>
                      <span className="text-lg font-bold text-zinc-100">
                        {Math.round(serverData?.fourteenDayAvg || 0)}
                      </span>
                    </div>
                  </div>

                  {/* Chart */}
                  <div className="h-10">
                    {serverData?.playerHistory && (
                      <PlayerCountChart
                        data={serverData.playerHistory}
                      />
                    )}
                  </div>
                </div>

                {/* Wipe Schedule */}
                <div className="bg-zinc-700/30 rounded-lg p-3">
                  <h4 className="text-xs uppercase text-zinc-400 font-semibold mb-2 flex items-center gap-1.5">
                    <ClockIcon className="h-3.5 w-3.5" />
                    Wipe Info
                  </h4>
                  <div className="space-y-1">
                    <div className="flex justify-between items-center">
                      <span className="text-sm">Last Wipe:</span>
                      <span className="text-sm font-medium">
                        {lastWipeText}
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm">Next Wipe:</span>
                      <span className="text-sm font-medium">
                        {formattedNextWipe}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Server Settings */}
                <div className="bg-zinc-700/30 rounded-lg p-3">
                  <h4 className="text-xs uppercase text-zinc-400 font-semibold mb-2 flex items-center gap-1.5">
                    <LightBulbIcon className="h-3.5 w-3.5" />
                    Server Settings
                  </h4>
                  <div className="space-y-1">
                    <div className="flex justify-between items-center">
                      <span className="text-sm">Group Limit:</span>
                      <span className="text-sm font-medium">
                        {displayGroupLimit}
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm">Gather Rate:</span>
                      <span className="text-sm font-medium">
                        {serverData?.details?.settings?.rates?.gather ? `${serverData.details.settings.rates.gather}x` : '1x'}
                      </span>
                    </div>
                    {serverData?.details?.settings?.rates && (
                      <>
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Component Rate:</span>
                          <span className="text-sm font-medium">
                            {serverData.details.settings.rates.component}x
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Craft Rate:</span>
                          <span className="text-sm font-medium">
                            {serverData.details.settings.rates.craft}x
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Scrap Rate:</span>
                          <span className="text-sm font-medium">
                            {serverData.details.settings.rates.scrap}x
                          </span>
                        </div>
                      </>
                    )}
                    <div className="flex justify-between items-center">
                      <span className="text-sm">Upkeep:</span>
                      <span className="text-sm font-medium">
                        {serverData?.details?.settings?.upkeep !== undefined ? `${Math.round(serverData.details.settings.upkeep * 100)}%` : '100%'}
                      </span>
                    </div>
                    {serverData?.details?.isModded && (
                      <div className="flex justify-between items-center">
                        <span className="text-sm">Type:</span>
                        <span className="text-sm font-medium text-amber-400">
                          Modded
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Map Info */}
                <Tooltip content="Paid members vote on next wipe's map!">
                  <div className="bg-zinc-700/30 rounded-lg p-3 cursor-help">
                    <h4 className="text-xs uppercase text-zinc-400 font-semibold mb-2 flex items-center gap-1.5">
                      <MapPinIcon className="h-3.5 w-3.5" />
                      Map Info
                    </h4>
                    <div className="space-y-1">
                      <div className="flex justify-between items-center">
                        <span className="text-sm">Map Size:</span>
                        <span className="text-sm font-medium">
                          {serverData?.details?.mapSize || '3500'}
                        </span>
                      </div>
                      {serverData?.details?.mapInfo?.biomePercentages && (
                        <div className="flex flex-col gap-1 mt-2">
                          <span className="text-xs text-zinc-400">Biomes:</span>
                          {Object.entries({
                            Desert: serverData.details.mapInfo.biomePercentages?.d ?? 0,
                            Snow: serverData.details.mapInfo.biomePercentages?.s ?? 0,
                            Forest: serverData.details.mapInfo.biomePercentages?.f ?? 0,
                            Tundra: serverData.details.mapInfo.biomePercentages?.t ?? 0
                          }).map(([biome, percentage]) => (
                            <div key={biome} className="flex justify-between items-center">
                              <span className="text-xs">{biome}</span>
                              <span className="text-xs font-medium">{Math.round(percentage)}%</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </Tooltip>
              </div>

              {/* Server Features Tags */}
              <div className="flex flex-wrap gap-2 mb-2" role="list">
                {server.features.map((feature, index) => (
                  <span
                    key={index}
                    className="px-2.5 py-1 bg-rust-orange/20 border border-rust-orange/20 rounded-full text-xs text-rust-orange"
                    role="listitem"
                  >
                    {feature}
                  </span>
                ))}
              </div>

              {/* Copy IP Button - Moved to expanded section */}
              <div onClick={(e) => e.stopPropagation()}>
                <Tooltip content="Press F1 in game → Paste in console → Press Enter">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      copyIpToClipboard();
                    }}
                    className={`flex items-center gap-2 w-full justify-center transition-all duration-200 copy-button ${copied ? 'bg-green-600 hover:bg-green-700' : ''}`}
                    title="Copy server connection command"
                    aria-label="Copy server connection command"
                  >
                    {copied ? (
                      <>
                        <CheckIcon className="h-4 w-4 animate-bounce" />
                        Copied!
                      </>
                    ) : (
                      <>
                        <DocumentDuplicateIcon className="h-4 w-4" />
                        Copy Connection Command
                      </>
                    )}
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
} 
