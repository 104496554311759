interface DiscordInviteResponse {
  approximate_member_count: number;
  approximate_presence_count: number;
  guild: {
    name: string;
    description: string;
    id: string;
  };
}

/**
 * Fetches Discord server information including member count
 * @returns Promise with Discord server information or null if there was an error
 */
export const getDiscordInfo = async (): Promise<DiscordInviteResponse | null> => {
  try {
    const response = await fetch('https://discord.com/api/invites/r4n?with_counts=true');
    
    if (!response.ok) {
      console.error('Failed to fetch Discord info:', response.status);
      return null;
    }
    
    const data = await response.json();
    return data as DiscordInviteResponse;
  } catch (error) {
    console.error('Error fetching Discord info:', error);
    return null;
  }
}; 