import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getSteamUserProfile, SteamUser, loginWithSteam } from '../services/steam';
import { track } from '@vercel/analytics';

interface AuthContextType {
  user: SteamUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
  login: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<SteamUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Check for authentication on initial load
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // Check if there's a stored Steam ID in localStorage
        const storedSteamId = localStorage.getItem('steamId');
        
        if (storedSteamId) {
          // Fetch user data from Steam API
          const userData = await getSteamUserProfile(storedSteamId);
          
          if (userData) {
            setUser(userData);
          } else {
            // If we couldn't get the user data, clear the stored ID
            localStorage.removeItem('steamId');
          }
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        localStorage.removeItem('steamId');
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  // Check if we're on the callback page
  useEffect(() => {
    const handleCallback = async () => {
      if (window.location.pathname === '/auth-callback') {
        try {
          setIsLoading(true);
          const urlParams = new URLSearchParams(window.location.search);
          
          // Get the claimed_id parameter which contains the Steam ID
          const claimedId = urlParams.get('openid.claimed_id') || '';
          const steamId = claimedId.split('/').pop();
          
          if (steamId) {
            // Store the Steam ID in localStorage
            localStorage.setItem('steamId', steamId);
            
            // Fetch user data
            const userData = await getSteamUserProfile(steamId);
            
            if (userData) {
              setUser(userData);
              
              // Track successful Steam login
              track('steam_login', { 
                steamId: userData.steamid,
                username: userData.personaname
              });
            }
          }
          
          // Redirect back to home page
          window.history.replaceState({}, document.title, '/');
        } catch (error) {
          console.error('Error handling callback:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    handleCallback();
  }, []);

  const login = () => {
    // Redirect to Steam login page
    loginWithSteam();
  };

  const logout = () => {
    // Clear user data and localStorage
    setUser(null);
    localStorage.removeItem('steamId');
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated: !!user,
        isLoading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}; 