/**
 * Configuration file for the Rust4Noobs website.
 * This file contains all the customizable content and settings for the website.
 */

/**
 * Represents a Rust server configuration
 * @interface ServerInfo
 */
export interface ServerInfo {
  /** The display name of the server as shown in the server list */
  name: string;
  /** The legal name of the server as shown in the footer */
  legalName?: string;
  /** A brief description of the server's unique features or focus */
  description: string;
  /** The server's connection IP in the format "connect IP:PORT" */
  ip: string;
  /** Maximum number of players allowed on the server */
  maxPlayers: number;
  /** Array of features/tags that describe the server (e.g., "No BP Wipe", "Monthly Wipe", "50% Upkeep") */
  features: string[];
  /** BattleMetrics server ID for live status and player count */
  battlemetricsId: string;
  /** RankEval server ID for leaderboards */
  rankEvalId?: string;
  /** Leaderboard configuration for this server */
  leaderboardConfig?: {
    isActive: boolean;
    teamLeaderboard: boolean;
    lifetimeLeaderboard: boolean;
  };
  /** Region of the server (e.g., "EU", "US", "AS") */
  region?: string;
}

/**
 * Represents a social media link configuration
 * @interface SocialLink
 */
export interface SocialLink {
  /** The name of the social media platform */
  platform: string;
  /** The full URL to the social media profile/page */
  url: string;
  /** Icon identifier - must match the icon name in the footer component
   * Currently supported: 'discord', 'twitter', 'steam'
   */
  icon: string;
}

/**
 * Represents a staff member configuration
 * @interface StaffMember
 */
export interface StaffMember {
  /** Staff member's display name */
  name: string;
  /** Staff member's role/position (e.g., "Owner", "Admin", "Moderator") */
  role: string;
  /** Discord user ID for linking to their Discord profile */
  discordId: string;
  /** Optional URL to the staff member's avatar image
   * If not provided, a generated avatar will be used
   */
  avatarUrl?: string;
}

/**
 * Represents a store rank configuration
 * @interface StoreRank
 */
export interface StoreRank {
  /** The name of the rank (e.g., "VIP", "Legend") */
  name: string;
  /** Icon type to display with the rank
   * - 'star': Used for premium/top tier ranks
   * - 'lock': Used for exclusive access ranks
   * - 'check': Used for standard ranks
   */
  icon: 'star' | 'lock' | 'check';
  /** Array of benefits/perks that come with the rank */
  benefits: string[];
  /** Optional flag to highlight this rank as popular */
  isPopular?: boolean;
}

/**
 * Main configuration interface for the website
 * @interface SiteConfig
 */
export interface SiteConfig {
  /** Website name - used in various places including title and footer */
  name: string;
  /** Legal name of the organization - used in footer copyright */
  legalName: string;
  /** Brief description of your server network - appears in hero section */
  description: string;
  /** Array of server configurations */
  servers: ServerInfo[];
  /** Discord invite link - used for the "Join Discord" button */
  discordInvite: string;
  /** Array of social media links - displayed in the footer */
  socialLinks: SocialLink[];
  /** Array of feature highlights - displayed in the features section
   * @property title - Feature name
   * @property description - Feature description
   * @property icon - Icon name from heroicons (must be added to iconMap in App.tsx)
   */
  features: {
    title: string;
    description: string;
    icon: string;
  }[];
  /** Google Analytics configuration */
  analytics: {
    /** Google Analytics ID (format: G-XXXXXXXXXX) */
    googleAnalyticsId: string;
  };
  /** Array of staff member configurations - displayed in staff section */
  staff: StaffMember[];
  /** Store section configuration */
  store: {
    /** Store section title */
    title: string;
    /** Store section description */
    description: string;
    /** URL to your store page */
    storeUrl: string;
    /** Array of rank configurations */
    ranks: StoreRank[];
  };
  discord: {
    feedbackWebhookUrl: string;
  };
}

/**
 * The actual site configuration
 * Customize these values to match your server network
 */
export const siteConfig: SiteConfig = {
  name: "Rust4Noobs",
  legalName: "4Noobs Global Limited",
  description: "A beginner-friendly Rust server network focused on helping new players learn and enjoy the game.",
  servers: [
    {
      name: "[EU] Rust4Noobs VANILLA | Solo | No BP wipe | Monthly",
      description: "Solo only vanilla server with monthly map wipes and no BP wipes.",
      ip: "connect solo.vanilla.4noobs.eu",
      maxPlayers: 100,
      features: ["Solo", "Vanilla", "No BP Wipe", "Monthly Wipe", "1x Rates"],
      battlemetricsId: "26889920",
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs VANILLA | Main | No BP wipe",
      description: "Our main vanilla server with monthly wipes and no BP wipes.",
      ip: "connect main.vanilla.4noobs.eu",
      maxPlayers: 100,
      features: ["Vanilla", "Monthly Wipe", "No BP Wipe", "Noob Friendly"],
      battlemetricsId: "26886565",
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs | Primitive | Solo",
      description: "Solo primitive server for a unique gameplay experience.",
      ip: "connect solo.primitive.4noobs.eu",
      maxPlayers: 100,
      features: ["Solo", "Primitive", "Noob Friendly", "Monthly Wipe", "No BP Wipe"],
      battlemetricsId: "31881016",
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs | Primitive | Solo/Duo",
      description: "Solo/Duo primitive server perfect for small groups.",
      ip: "connect duo.primitive.4noobs.eu",
      maxPlayers: 100,
      features: ["Duo", "Primitive", "Noob Friendly", "Monthly Wipe", "No BP Wipe"],
      battlemetricsId: "31865033",
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs 50% upkeep | Solo",
      description: "Solo server with reduced upkeep cost for easier base maintenance.",
      ip: "connect solo.softcore.4noobs.eu",
      maxPlayers: 100,
      features: ["Solo", "50% Upkeep", "Noob Friendly", "Monthly Wipe", "No BP Wipe", "Softcore"],
      battlemetricsId: "25445683",
      rankEvalId: "64751e611e6fd95d2b8b4a1c",
      leaderboardConfig: {
        isActive: true,
        teamLeaderboard: true,
        lifetimeLeaderboard: false
      },
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs 50% upkeep | Main",
      description: "Main server with reduced upkeep cost for groups up to 6!",
      ip: "connect main.softcore.4noobs.eu",
      maxPlayers: 100,
      features: ["50% Upkeep", "Noob Friendly", "Monthly Wipe", "No BP Wipe", "Softcore"],
      battlemetricsId: "25441004",
      rankEvalId: "6439305afc0412e061de487c",
      leaderboardConfig: {
        isActive: true,
        teamLeaderboard: true,
        lifetimeLeaderboard: false
      },
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs 25% upkeep | PvE",
      description: "PvE focused server with significantly reduced upkeep cost.",
      ip: "connect pve.softcore.4noobs.eu",
      maxPlayers: 100,
      features: ["PvE", "25% Upkeep", "Noob Friendly", "Monthly Wipe", "No BP Wipe"],
      battlemetricsId: "28065740",
      rankEvalId: "64cb92c55826d364fea99d24",
      leaderboardConfig: {
        isActive: true,
        teamLeaderboard: true,
        lifetimeLeaderboard: false
      },
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs | Primitive | Main",
      description: "Main primitive server open to all groups up to 6!",
      ip: "connect main.primitive.4noobs.eu",
      maxPlayers: 100,
      features: ["Primitive", "Noob Friendly", "Monthly Wipe", "No BP Wipe"],
      battlemetricsId: "31717591",
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs 50% upkeep | Solo/Duo",
      description: "Solo/Duo server with reduced upkeep cost.",
      ip: "connect duo.softcore.4noobs.eu",
      maxPlayers: 100,
      features: ["Duo", "50% Upkeep", "Noob Friendly", "Monthly Wipe", "No BP Wipe", "Softcore"],
      battlemetricsId: "25448728",
      rankEvalId: "65b057c02b01723810476cfd",
      leaderboardConfig: {
        isActive: true,
        teamLeaderboard: true,
        lifetimeLeaderboard: false
      },
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs 50% upkeep | Solo-Quad | Noob friendly | Monthly",
      description: "Team-based server with up to 4 players per team and reduced upkeep costs.",
      ip: "connect quad.softcore.4noobs.eu",
      maxPlayers: 100,
      features: ["Quad", "50% Upkeep", "No BP Wipe", "Monthly Wipe", "Softcore"],
      battlemetricsId: "26994476",
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs 50% upkeep | Solo/Duo/Trio | Noob friendly | Monthly",
      description: "Team-based server with up to 3 players per team and reduced upkeep costs. No BP wipe.",
      ip: "connect trio.softcore.4noobs.eu",
      maxPlayers: 100,
      features: ["Trio", "50% Upkeep", "No BP Wipe", "Monthly Wipe", "Softcore"],
      battlemetricsId: "25442368",
      rankEvalId: "64751c871e6fd95d2b8b49ef",
      leaderboardConfig: {
        isActive: true,
        teamLeaderboard: true,
        lifetimeLeaderboard: false
      },
      region: "EU"
    },
    {
      name: "[EU] Rust4Noobs 2x | Primitive | Solo/Duo | Noob friendly | Monthly",
      description: "2x gather rate primitive server for solo and duo players with monthly wipes.",
      ip: "connect 2x.duo.primitive.4noobs.eu",
      maxPlayers: 100,
      features: ["Duo", "2x Rates", "Primitive", "Monthly Wipe", "Noob Friendly", "No BP Wipe"],
      battlemetricsId: "31884178",
      region: "EU"
    }
  ],
  discordInvite: "https://discord.gg/r4n",
  socialLinks: [
    {
      platform: "Discord",
      url: "https://discord.gg/r4n",
      icon: "discord"
    },
    {
      platform: "Twitter",
      url: "https://twitter.com/rust4noobs",
      icon: "twitter"
    },
    {
      platform: "Steam",
      url: "https://steamcommunity.com/groups/rust4noobs",
      icon: "steam"
    }
  ],
  features: [
    {
      title: "Beginner Friendly",
      description: "Perfect environment for new players to learn Rust",
      icon: "AcademicCapIcon"
    },
    {
      title: "Active Community",
      description: "Join our friendly and helpful community",
      icon: "UsersIcon"
    },
    {
      title: "Regular Events",
      description: "Participate in exciting server events",
      icon: "CalendarIcon"
    },
    {
      title: "Multiple Servers",
      description: "Choose from various game modes",
      icon: "CubeIcon"
    }
  ],
  analytics: {
    googleAnalyticsId: 'G-ZRV89KTMP0', // Replace with your Google Analytics ID
  },
  staff: [
    {
      name: "FeatherFall",
      role: "Owner",
      discordId: "1030584039775682602",
      avatarUrl: "https://cdn.4noobs.co/R4N/Staff%20photos/20250303151122_1_cropped.png"
    },
    {
      name: "Illuminatixs",
      role: "Manager",
      discordId: "268671832348033024",
      avatarUrl: "https://cdn.4noobs.co/R4N/Staff%20photos/20250303151356_1_cropped.png"
    },
    {
      name: "Niffler",
      role: "Senior admin",
      discordId: "1208743782770802730",
      avatarUrl: "https://cdn.4noobs.co/R4N/Staff%20photos/20250303152207_1_cropped.png"
    },
    {
      name: "ShineyHawkz",
      role: "Senior admin",
      discordId: "253591466406838282",
      avatarUrl: "https://cdn.4noobs.co/R4N/Staff%20photos/20250303151541_1_cropped.png"
    },
    {
      name: "Matty",
      role: "Admin",
      discordId: "415725999922348042",
      avatarUrl: "https://cdn.4noobs.co/R4N/Staff%20photos/20250303152557_1_cropped.png"
    },
    {
      name: "Parrot",
      role: "Admin",
      discordId: "663960143880519681",
      avatarUrl: "https://cdn.4noobs.co/R4N/Staff%20photos/20250303153026_1_cropped.png"
    },
    {
      name: "Will Allison",
      role: "Admin",
      discordId: "849752867455107123",
      avatarUrl: "https://cdn.4noobs.co/R4N/Staff%20photos/20250303152741_1_cropped.png"
    },
    {
      name: "Pivot",
      role: "Junior admin",
      discordId: "579326793928933391",
      avatarUrl: "https://www.gravatar.com/avatar/?d=mp"
    },
    {
      name: "Cawwots",
      role: "Moderator",
      discordId: "230574419116818432",
      avatarUrl: "https://www.gravatar.com/avatar/?d=mp"
    },
    {
      name: "KuBakPOWA",
      role: "Moderator",
      discordId: "412236129492795413",
      avatarUrl: "/images/kubak.webp"
    },
    {
      name: "Jodie",
      role: "Moderator",
      discordId: "417776880041852934",
      avatarUrl: "https://www.gravatar.com/avatar/?d=mp"
    }
  ],
  store: {
    title: "Support Our Servers",
    description: "Support our community and get exclusive benefits by purchasing a rank. Your contribution helps us maintain and improve our servers.",
    storeUrl: "https://store.4noobs.co/products?tag=products",
    ranks: [
      {
        name: "VIP",
        icon: "lock",
        benefits: [
          "Access to map voting channels.",
          "VIP tag in-game",
          "Modded server plugin access"
        ]
      },
      {
        name: "VIP+",
        icon: "check",
        benefits: [
          "All VIP benefits",
          "VIP+ tag in-game",
          "Change the colour of your in-game name"
        ],
        isPopular: true
      },
      {
        name: "Legend",
        icon: "star",
        benefits: [
          "All VIP+ benefits",
          "Rainbow coloured in-game name",
          "Early access to unreleased plans/servers"
        ]
      }
    ]
  },
  discord: {
    feedbackWebhookUrl: import.meta.env.VITE_DISCORD_FEEDBACK_WEBHOOK_URL || '',
  },
}; 