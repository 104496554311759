import { siteConfig } from '../config/site.config';

interface FeedbackData {
  rating: number;
  comment?: string;
  timestamp: string;
  url: string;
}

export const submitFeedback = async (data: FeedbackData): Promise<boolean> => {
  const webhookUrl = siteConfig.discord.feedbackWebhookUrl;
  
  if (!webhookUrl) {
    console.error('Discord webhook URL not configured');
    return false;
  }

  try {
    const stars = '⭐'.repeat(data.rating) + '☆'.repeat(5 - data.rating);
    const message = {
      embeds: [{
        title: 'New Site Feedback',
        color: 0xE67E22, // Rust orange
        fields: [
          {
            name: 'Rating',
            value: stars,
            inline: true
          },
          {
            name: 'Page',
            value: data.url,
            inline: true
          },
          {
            name: 'Timestamp',
            value: data.timestamp,
            inline: true
          }
        ],
        description: data.comment || 'No additional comments provided.'
      }]
    };

    const response = await fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });

    return response.ok;
  } catch (error) {
    console.error('Failed to submit feedback:', error);
    return false;
  }
}; 