import type { ReactNode } from 'react';
import { useState, useRef } from 'react';
import { createPortal } from 'react-dom';

interface TooltipProps {
  content: string;
  children: ReactNode;
}

export const Tooltip = ({ content, children }: TooltipProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);

  const updateTooltipPosition = () => {
    if (!triggerRef.current || !isHovered) return null;

    const rect = triggerRef.current.getBoundingClientRect();
    const tooltipX = rect.left + rect.width / 2;
    const tooltipY = rect.top;

    return createPortal(
      <div 
        className="fixed transform -translate-x-1/2 px-3 py-2 
                   bg-zinc-800 text-zinc-200 text-sm rounded-lg shadow-xl
                   whitespace-nowrap pointer-events-none z-[9999]
                   border border-zinc-700"
        style={{
          left: `${tooltipX}px`,
          top: `${tooltipY - 40}px`,
          opacity: isHovered ? 1 : 0,
          transition: 'opacity 200ms'
        }}
      >
        {content}
        <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 
                       border-4 border-transparent border-t-zinc-800" />
      </div>,
      document.body
    );
  };

  return (
    <div 
      ref={triggerRef}
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {updateTooltipPosition()}
    </div>
  );
}; 