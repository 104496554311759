import { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import type { ServerInfo } from '../config/site.config';
import EnhancedServerCard from './EnhancedServerCard';

// Custom hook for responsive design
const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
};

interface PaginatedServerListProps {
  servers: ServerInfo[];
}

export const PaginatedServerList = ({ servers }: PaginatedServerListProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useMediaQuery('(max-width: 768px)');
  
  // Constants for pagination
  const serversPerPage = isMobile ? 3 : servers.length; // Show all on desktop, 3 on mobile
  const totalPages = Math.ceil(servers.length / serversPerPage);
  
  // Reset to page 1 when servers list changes or when switching between mobile and desktop
  useEffect(() => {
    setCurrentPage(1);
  }, [servers.length, isMobile]);
  
  // Calculate which servers to display based on current page (only for mobile)
  const indexOfLastServer = currentPage * serversPerPage;
  const indexOfFirstServer = indexOfLastServer - serversPerPage;
  const currentServers = isMobile 
    ? servers.slice(indexOfFirstServer, indexOfLastServer)
    : servers; // On desktop, show all servers

  // Page navigation
  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    window.scrollTo({ top: document.getElementById('servers')?.offsetTop || 0, behavior: 'smooth' });
  };
  
  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    window.scrollTo({ top: document.getElementById('servers')?.offsetTop || 0, behavior: 'smooth' });
  };

  return (
    <div>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
        {currentServers.map((server) => (
          <EnhancedServerCard key={server.name} server={server} />
        ))}
      </div>
      
      {/* Pagination Controls - Only show on mobile if more than one page */}
      {isMobile && totalPages > 1 && (
        <div className="flex justify-center items-center gap-4 mt-8">
          <button 
            onClick={goToPreviousPage} 
            disabled={currentPage === 1}
            className={`p-2 rounded-full ${
              currentPage === 1 
                ? 'bg-zinc-700/50 text-zinc-500 cursor-not-allowed' 
                : 'bg-zinc-700 hover:bg-zinc-600 text-white'
            }`}
            aria-label="Previous page"
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
          
          <div className="text-zinc-300">
            Page {currentPage} of {totalPages}
          </div>
          
          <button 
            onClick={goToNextPage} 
            disabled={currentPage === totalPages}
            className={`p-2 rounded-full ${
              currentPage === totalPages 
                ? 'bg-zinc-700/50 text-zinc-500 cursor-not-allowed' 
                : 'bg-zinc-700 hover:bg-zinc-600 text-white'
            }`}
            aria-label="Next page"
          >
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </div>
      )}
    </div>
  );
}; 