import { FunnelIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Button } from './ui/Button';
import { useState, useEffect } from 'react';

export interface ServerFilters {
  groupLimit: string | null;
  upkeep: string | null;
  gameMode: string | null;
}

interface ServerFiltersProps {
  filters: ServerFilters;
  onFilterChange: (filters: ServerFilters) => void;
}

const GROUP_LIMITS = [
  { label: 'All', value: null },
  { label: 'Solo', value: 'Solo' },
  { label: 'Duo', value: 'Duo' },
  { label: 'Trio', value: 'Trio' },
  { label: 'Quad', value: 'Quad' }
];

const UPKEEP_OPTIONS = [
  { label: 'All', value: null },
  { label: '100%', value: '100' },
  { label: '50%', value: '50' },
  { label: '25%', value: '25' }
];

const GAME_MODES = [
  { label: 'All', value: null },
  { label: 'Vanilla', value: 'vanilla' },
  { label: 'Primitive', value: 'primitive' },
  { label: 'PvE', value: 'pve' },
  { label: 'Softcore', value: 'softcore' }
];

// Custom hook for responsive design
const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
};

export default function ServerFilters({ filters, onFilterChange }: ServerFiltersProps) {
  const [customSize, setCustomSize] = useState<string>('');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isExpanded, setIsExpanded] = useState(!isMobile); // Expanded by default on desktop

  // Update isExpanded when screen size changes
  useEffect(() => {
    setIsExpanded(!isMobile); // Always expanded on desktop
  }, [isMobile]);

  const handleFilterChange = (filterType: keyof ServerFilters, value: string | null) => {
    onFilterChange({
      ...filters,
      [filterType]: value
    });
  };

  const handleCustomSizeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (customSize) {
      handleFilterChange('groupLimit', `${customSize} Players`);
    }
  };

  const handleCustomSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Only allow numbers between 1 and 100
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) > 0 && parseInt(value) <= 100)) {
      setCustomSize(value);
    }
  };

  // Check if any filters are active
  const hasActiveFilters = filters.groupLimit !== null || filters.upkeep !== null || filters.gameMode !== null;

  return (
    <div className="mb-8 bg-zinc-800/70 backdrop-blur-sm border border-zinc-700 rounded-xl p-4">
      <div 
        className={`flex items-center justify-between ${isMobile ? 'cursor-pointer' : ''}`}
        onClick={() => isMobile && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-2">
          <FunnelIcon className="h-5 w-5 text-zinc-400" />
          <h3 className="text-lg font-semibold">Filter Servers</h3>
          {hasActiveFilters && (
            <span className="bg-rust-orange/80 text-white text-xs px-2 py-1 rounded-full">
              Active
            </span>
          )}
        </div>
        {isMobile && (
          <div className="text-zinc-400">
            {isExpanded ? (
              <ChevronUpIcon className="h-5 w-5" />
            ) : (
              <ChevronDownIcon className="h-5 w-5" />
            )}
          </div>
        )}
      </div>
      
      {isExpanded && (
        <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4 animate-fadeIn">
          {/* Group Limit Filter */}
          <div>
            <label className="block text-sm font-medium text-zinc-400 mb-2">
              Group Size
            </label>
            <div className="flex flex-wrap gap-2">
              {GROUP_LIMITS.map(option => (
                <Button
                  key={option.label}
                  variant={filters.groupLimit === option.value ? "primary" : "secondary"}
                  size="sm"
                  onClick={() => {
                    handleFilterChange('groupLimit', option.value);
                    setShowCustomInput(false);
                    setCustomSize('');
                  }}
                >
                  {option.label}
                </Button>
              ))}
              <Button
                variant={showCustomInput ? "primary" : "secondary"}
                size="sm"
                onClick={() => {
                  setShowCustomInput(!showCustomInput);
                  if (!showCustomInput) {
                    handleFilterChange('groupLimit', null);
                  }
                }}
              >
                Custom
              </Button>
            </div>
            {showCustomInput && (
              <form onSubmit={handleCustomSizeSubmit} className="mt-2 flex gap-2">
                <input
                  type="text"
                  value={customSize}
                  onChange={handleCustomSizeChange}
                  placeholder="Enter group size"
                  className="bg-zinc-700 text-white rounded-lg px-3 py-1.5 text-sm w-32 border border-zinc-600 focus:outline-none focus:border-rust-orange"
                  autoFocus
                />
                <Button
                  type="submit"
                  size="sm"
                  disabled={!customSize}
                >
                  Apply
                </Button>
              </form>
            )}
          </div>

          {/* Upkeep Filter */}
          <div>
            <label className="block text-sm font-medium text-zinc-400 mb-2">
              Upkeep Rate
            </label>
            <div className="flex flex-wrap gap-2">
              {UPKEEP_OPTIONS.map(option => (
                <Button
                  key={option.label}
                  variant={filters.upkeep === option.value ? "primary" : "secondary"}
                  size="sm"
                  onClick={() => handleFilterChange('upkeep', option.value)}
                >
                  {option.label}
                </Button>
              ))}
            </div>
          </div>

          {/* Game Mode Filter */}
          <div>
            <label className="block text-sm font-medium text-zinc-400 mb-2">
              Game Mode
            </label>
            <div className="flex flex-wrap gap-2">
              {GAME_MODES.map(option => (
                <Button
                  key={option.label}
                  variant={filters.gameMode === option.value ? "primary" : "secondary"}
                  size="sm"
                  onClick={() => handleFilterChange('gameMode', option.value)}
                >
                  {option.label}
                </Button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 