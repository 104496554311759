/**
 * Steam Authentication Service
 * Handles the Steam OpenID authentication flow
 */

export interface SteamUser {
  steamid: string;
  personaname: string;
  profileurl: string;
  avatar: string;
  avatarmedium: string;
  avatarfull: string;
}

/**
 * Initiates the Steam OpenID authentication process
 * Redirects the user to Steam login page via our server API
 */
export const loginWithSteam = (): void => {
  // Redirect to our server-side login endpoint, which will redirect to Steam
  window.location.href = '/api/auth/steam';
};

/**
 * Validates the Steam authentication response by sending the params to our server API
 * @param queryParams - URL query parameters from the callback URL
 * @returns Promise<SteamUser | null> User data if authentication was successful, null otherwise
 */
export const handleSteamCallback = async (queryParams: URLSearchParams): Promise<SteamUser | null> => {
  try {
    // Send the callback parameters to our server API for validation
    const callbackUrl = `/api/auth/steam/validate?${queryParams.toString()}`;
    const response = await fetch(callbackUrl);
    
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Steam validation failed:', errorData.error);
      return null;
    }
    
    const data = await response.json();
    
    // Check if the response includes a steamId directly (from our improved validation endpoint)
    if (data.steamId && !data.validated) {
      console.warn('Steam validation was skipped or failed, but ID was provided. Using anyway.');
      // Fetch the user profile using the provided Steam ID
      return await getSteamUserProfile(data.steamId);
    }
    
    return extractSteamUser(data);
  } catch (error) {
    console.error('Error handling Steam callback:', error);
    return null;
  }
};

/**
 * Extracts the SteamUser from the API response
 * @param data - The response data from the API
 * @returns SteamUser object or null if data is invalid
 */
const extractSteamUser = (data: any): SteamUser | null => {
  try {
    // Check if we're dealing with a direct response that already has player data in response.players
    const player = data.response?.players?.[0];
    if (player) {
      return {
        steamid: player.steamid,
        personaname: player.personaname,
        profileurl: player.profileurl,
        avatar: player.avatar,
        avatarmedium: player.avatarmedium,
        avatarfull: player.avatarfull
      };
    }
    
    // If we have a player object directly in the data (new format)
    if (data.player || (data.response && !data.response.players)) {
      const playerData = data.player || data;
      return {
        steamid: playerData.steamid || '',
        personaname: playerData.personaname || 'Unknown',
        profileurl: playerData.profileurl || '',
        avatar: playerData.avatar || '',
        avatarmedium: playerData.avatarmedium || '',
        avatarfull: playerData.avatarfull || ''
      };
    }
    
    console.error('No player data in response');
    return null;
  } catch (error) {
    console.error('Error extracting Steam user data:', error);
    return null;
  }
};

/**
 * Fetches a user's Steam profile information
 * @param steamId - The user's Steam ID
 * @returns Promise<SteamUser | null> User data if successful, null otherwise
 */
export const getSteamUserProfile = async (steamId: string): Promise<SteamUser | null> => {
  try {
    // Use our server-side API to fetch user data
    const response = await fetch(`/api/steam/user/${steamId}`);
    
    if (!response.ok) {
      console.error('Failed to fetch Steam user data:', response.status);
      return null;
    }
    
    const data = await response.json();
    return extractSteamUser(data);
  } catch (error) {
    console.error('Error fetching Steam user profile:', error);
    return null;
  }
}; 