import { siteConfig } from './config/site.config';
import { Navigation } from './components/Navigation';
import { GoogleAnalytics } from './components/GoogleAnalytics';
import { Routes, Route, useLocation } from 'react-router-dom';
import ServerFilters, { ServerFilters as ServerFiltersType } from './components/ServerFilters';
import { useState, useEffect, Suspense, lazy, memo, useMemo } from 'react';
import { Tooltip } from './components/ui/Tooltip';
import { BackToTop } from './components/BackToTop';
import { FeedbackForm } from './components/FeedbackForm';
import { getDiscordInfo } from './services/discord';
import { HeroSection } from './components/HeroSection';
import { AboutSection } from './components/AboutSection';
import { MetaTags } from './components/MetaTags';
import { PaginatedServerList } from './components/PaginatedServerList';

// Lazy load route components
const PaginatedStaffTeam = lazy(() => import('./components/PaginatedStaffTeam'));
const Store = lazy(() => import('./components/Store'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const LeaderboardsPage = lazy(() => import('./pages/LeaderboardsPage'));
const PlayerStatsPage = lazy(() => import('./pages/PlayerStatsPage'));
const TeamStatsPage = lazy(() => import('./pages/TeamStatsPage'));
const AuthCallback = lazy(() => import('./pages/AuthCallback'));

// Loading fallback component
const LoadingFallback = memo(() => (
  <div className="min-h-screen bg-zinc-900 pt-24 pb-12 px-4">
    <div className="max-w-7xl mx-auto">
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-rust-orange mx-auto"></div>
      </div>
    </div>
  </div>
));

// Lazy load feature icons
const iconMap = {
  AcademicCapIcon: lazy(() => import('@heroicons/react/24/outline/AcademicCapIcon')),
  UsersIcon: lazy(() => import('@heroicons/react/24/outline/UsersIcon')),
  CalendarIcon: lazy(() => import('@heroicons/react/24/outline/CalendarIcon')),
  CubeIcon: lazy(() => import('@heroicons/react/24/outline/CubeIcon')),
};


// Memoized features grid component
const FeaturesGrid = memo(() => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
    {siteConfig.features.map((feature) => {
      const Icon = iconMap[feature.icon as keyof typeof iconMap];
      return (
        <div key={feature.title} className="card text-center">
          <Suspense fallback={<div className="w-12 h-12" />}>
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-rust-red/10 text-rust-red mb-4">
              <Icon className="w-6 h-6" />
            </div>
          </Suspense>
          <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
          <p className="text-zinc-300">{feature.description}</p>
        </div>
      );
    })}
  </div>
));

const HomePage = memo(({ scrollToElement }: { scrollToElement: (id: string) => void }) => {
  const [filters, setFilters] = useState<ServerFiltersType>({
    groupLimit: null,
    upkeep: null,
    gameMode: null
  });

  // Filter servers based on selected filters
  const filteredServers = useMemo(() => 
    siteConfig.servers.filter(server => {
      // Helper function to check if a feature matches a filter value
      const hasFeature = (feature: string, filterValue: string | null) => {
        if (!filterValue) return true;
        return feature.toLowerCase().includes(filterValue.toLowerCase());
      };

      // Helper function to extract numeric group size from feature
      const getGroupSize = (feature: string): number | null => {
        const featureLower = feature.toLowerCase();
        
        // Check for specific group sizes first with more precise patterns
        if (/^solo only$|^solo server$|^solo$/i.test(feature)) return 1;
        if (/^duo$|^duo only$|^duo server$/i.test(feature)) return 2;
        if (/^trio$|^trio only$|^trio server$/i.test(feature)) return 3;
        if (/^quad$|^quad only$|^quad server$/i.test(feature)) return 4;
        
        // Check for combined group sizes
        if (featureLower.includes('solo/duo')) return 2;
        if (featureLower.includes('solo/duo/trio')) return 3;
        if (featureLower.includes('solo-quad')) return 4;
        
        // Check for max group indicators
        if (featureLower.includes('max 6')) return 6;
        if (featureLower.includes('up to 6')) return 6;
        if (featureLower.includes('6 max')) return 6;
        
        // Check for custom group sizes (e.g., "6 Players Max", "Max 6")
        const numberMatch = feature.match(/\b(\d+)\b/);
        if (numberMatch) {
          const size = parseInt(numberMatch[1]);
          // If we find a number in the feature that matches our target size, return it
          return size;
        }
        
        return null;
      };

      // Group Limit filter
      if (filters.groupLimit) {
        const groupLimit = filters.groupLimit; // Capture in a const to help TypeScript
        const matchesGroupLimit = server.features.some(feature => {
          // Handle custom group size (e.g., "6 Players")
          if (groupLimit.endsWith('Players')) {
            const targetSize = parseInt(groupLimit);
            
            // Special handling for common group sizes
            if (targetSize === 3) {
              return feature.toLowerCase().includes('trio') || getGroupSize(feature) === 3;
            }
            if (targetSize === 4) {
              return feature.toLowerCase().includes('quad') || getGroupSize(feature) === 4;
            }
            if (targetSize === 6) {
              // For 6, check if it's a main server or explicitly mentions 6 players
              const featureLower = feature.toLowerCase();
              const isMainServer = server.name.toLowerCase().includes('main') || 
                                 server.description.toLowerCase().includes('up to 6') ||
                                 featureLower.includes('main');
              return isMainServer || getGroupSize(feature) === 6;
            }
            
            // For other sizes, check the numeric value
            const groupSize = getGroupSize(feature);
            return groupSize === targetSize;
          }
          
          // Handle preset sizes (Solo, Duo, etc.)
          if (groupLimit === 'Solo') {
            // For Solo, strictly match solo-only servers
            return /^solo only$|^solo server$|^solo$/i.test(feature) && 
                   !feature.toLowerCase().includes('duo') && 
                   !feature.toLowerCase().includes('trio') && 
                   !feature.toLowerCase().includes('quad');
          }
          
          // For other preset sizes, use the normal feature matching
          return hasFeature(feature, groupLimit);
        });
        if (!matchesGroupLimit) return false;
      }

      // Upkeep filter
      if (filters.upkeep) {
        const matchesUpkeep = server.features.some(feature => {
          // For 100% upkeep, also match vanilla servers
          if (filters.upkeep === '100') {
            return feature.toLowerCase() === 'vanilla' || feature.includes('100% Upkeep');
          }
          return feature.includes(`${filters.upkeep}% Upkeep`);
        });
        if (!matchesUpkeep) return false;
      }

      // Game Mode filter
      if (filters.gameMode) {
        const matchesGameMode = server.features.some(feature =>
          hasFeature(feature, filters.gameMode)
        );
        if (!matchesGameMode) return false;
      }

      return true;
    }),
    [filters]
  );

  return (
    <>
      <HeroSection 
        onStoreClick={() => window.open(siteConfig.store.storeUrl, '_blank')}
        onViewServersClick={() => scrollToElement('servers')}
        onScrollToAbout={() => scrollToElement('about')}
      />

      <AboutSection />

      {/* Features Section */}
      <section id="features" className="py-20 px-4 bg-zinc-800">
        <div className="max-w-7xl mx-auto">
          <h2 className="section-title">Why Choose Us?</h2>
          <FeaturesGrid />
        </div>
      </section>

      {/* Servers Section */}
      <section id="servers" className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="section-title">Our Servers</h2>
          
          <ServerFilters
            filters={filters}
            onFilterChange={setFilters}
          />
          
          {filteredServers.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-lg text-zinc-400">
                No servers match the selected filters.
              </p>
            </div>
          ) : (
            <PaginatedServerList servers={filteredServers} />
          )}
        </div>
      </section>

      <Suspense fallback={<LoadingFallback />}>
        <PaginatedStaffTeam />
        <Store />
      </Suspense>
    </>
  );
});

function App() {
  const location = useLocation();
  const [discordMemberCount, setDiscordMemberCount] = useState<number | null>(null);

  // Get page-specific meta tags
  const getMetaTags = () => {
    switch (location.pathname) {
      case '/':
        return {
          title: "Rust4Noobs - Best Beginner-Friendly Rust Servers | PvE & PvP",
          description: "Join the best beginner-friendly Rust servers with PvE, Solo, Duo, Trio & Quad options. Low upkeep rates, active admins, and helpful community. Perfect for new Rust players!",
          type: 'website',
          keywords: "rust servers, beginner rust, rust for beginners, pve rust, solo rust, duo rust, noob friendly rust, rust learning server, rust community server, low upkeep rust"
        };
      case '/privacy':
        return {
          title: 'Privacy Policy',
          description: `Privacy Policy for ${siteConfig.name} - Learn how we protect your data and privacy.`,
          type: 'article'
        };
      case '/leaderboards':
        return {
          title: 'Leaderboards',
          description: `Check out the top players and teams on ${siteConfig.name} servers.`,
          type: 'website'
        };
      default:
        if (location.pathname.includes('/leaderboards/player/')) {
          return {
            title: 'Player Stats',
            description: `View detailed player statistics and achievements on ${siteConfig.name} servers.`,
            type: 'profile'
          };
        }
        if (location.pathname.includes('/leaderboards/team/')) {
          return {
            title: 'Team Stats',
            description: `View detailed team statistics and achievements on ${siteConfig.name} servers.`,
            type: 'profile'
          };
        }
        return {
          title: siteConfig.name,
          description: siteConfig.description,
          type: 'website'
        };
    }
  };

  const metaTags = getMetaTags();

  // Fetch Discord member count
  useEffect(() => {
    const fetchDiscordInfo = async () => {
      try {
        const discordInfo = await getDiscordInfo();
        if (discordInfo) {
          setDiscordMemberCount(discordInfo.approximate_member_count);
        }
      } catch (error) {
        console.error('Failed to fetch Discord info:', error);
      }
    };

    fetchDiscordInfo();
    
    // Refresh every 5 minutes
    const interval = setInterval(fetchDiscordInfo, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  // Format member count with commas
  const formatMemberCount = (count: number): string => {
    return count.toLocaleString();
  };

  const scrollToElement = (elementId: string) => {
    document.getElementById(elementId)?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <div className="min-h-screen bg-zinc-900">
      <MetaTags {...metaTags} />
      <GoogleAnalytics />
      {location.pathname !== '/privacy' && <Navigation />}
      
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route path="/" element={<HomePage scrollToElement={scrollToElement} />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/leaderboards" element={<LeaderboardsPage />} />
          <Route path="/leaderboards/player/:steamId/:serverId" element={<PlayerStatsPage />} />
          <Route path="/leaderboards/team/:teamId/:serverId" element={<TeamStatsPage />} />
          <Route path="/auth-callback" element={<AuthCallback />} />
        </Routes>

        {location.pathname !== '/privacy' && (
          <footer className="bg-zinc-800 py-8 px-4">
            <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
              <div className="text-zinc-400 mb-4 md:mb-0">
                © 2024 {siteConfig.legalName}. All rights reserved.
              </div>
              <div className="flex space-x-6">
                {siteConfig.socialLinks.map((link) => (
                  <Tooltip key={link.platform} content={
                    link.platform === 'Discord' && discordMemberCount 
                      ? `${link.platform} - ${formatMemberCount(discordMemberCount)} members` 
                      : link.platform
                  }>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-zinc-400 hover:text-rust-orange transition-colors"
                      aria-label={link.platform}
                    >
                      {link.icon === 'discord' && (
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0 12.64 12.64 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057 19.9 19.9 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028c.462-.63.874-1.295 1.226-1.994.021-.041.001-.09-.041-.106a13.107 13.107 0 0 1-1.872-.892.077.077 0 0 1-.008-.128 10.2 10.2 0 0 0 .372-.292.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127 12.299 12.299 0 0 1-1.873.892.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z"/>
                        </svg>
                      )}
                      {link.icon === 'twitter' && (
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                        </svg>
                      )}
                      {link.icon === 'steam' && (
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M11.979 0C5.678 0 .511 4.86.022 11.037l6.432 2.658c.545-.371 1.203-.59 1.912-.59.063 0 .125.004.188.006l2.861-4.142V8.91c0-2.495 2.028-4.524 4.524-4.524 2.494 0 4.524 2.031 4.524 4.527s-2.03 4.525-4.524 4.525h-.105l-4.076 2.911c0 .052.004.105.004.159 0 1.875-1.515 3.396-3.39 3.396-1.635 0-3.016-1.173-3.331-2.727L.436 15.27C1.862 20.307 6.486 24 11.979 24c6.627 0 11.999-5.373 11.999-12S18.605 0 11.979 0zM7.54 18.21l-1.473-.61c.262.543.714.999 1.314 1.25 1.297.539 2.793-.076 3.332-1.375.263-.63.264-1.319.005-1.949s-.75-1.121-1.377-1.383c-.624-.26-1.29-.249-1.29-.03l1.523.63c.956.4 1.409 1.5 1.009 2.455-.397.957-1.497 1.41-2.454 1.012zm11.415-9.303c0-1.662-1.353-3.015-3.015-3.015-1.665 0-3.015 1.353-3.015 3.015 0 1.665 1.35 3.015 3.015 3.015 1.663 0 3.015-1.35 3.015-3.015zm-5.273-.005c0-1.252 1.013-2.266 2.265-2.266 1.249 0 2.266 1.014 2.266 2.266 0 1.251-1.017 2.265-2.266 2.265-1.253 0-2.265-1.014-2.265-2.265z"/>
                        </svg>
                      )}
                    </a>
                  </Tooltip>
                ))}
              </div>
            </div>
          </footer>
        )}

        <BackToTop />
        <FeedbackForm />
      </Suspense>
    </div>
  );
}

export default App;
