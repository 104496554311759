import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { siteConfig } from '../config/site.config';

interface MetaTagsProps {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  keywords?: string;
}

export const MetaTags = ({ 
  title = siteConfig.name,
  description = siteConfig.description,
  image = '/og-image.jpg',
  type = 'website',
  keywords
}: MetaTagsProps) => {
  const location = useLocation();
  const fullTitle = title === siteConfig.name ? title : `${title} | ${siteConfig.name}`;
  const canonicalUrl = `https://4noobs.co${location.pathname}`;

  useEffect(() => {
    // Update meta tags
    document.title = fullTitle;
    
    // Update meta description and keywords
    const metaTags = {
      'description': description,
      'keywords': keywords,
      'author': siteConfig.legalName,
      'robots': 'index, follow'
    };

    Object.entries(metaTags).forEach(([name, content]) => {
      if (content) {
        let metaTag = document.querySelector(`meta[name="${name}"]`);
        if (!metaTag) {
          metaTag = document.createElement('meta');
          metaTag.setAttribute('name', name);
          document.head.appendChild(metaTag);
        }
        metaTag.setAttribute('content', content);
      }
    });

    // Update Open Graph tags
    const ogTags = {
      'og:title': fullTitle,
      'og:description': description,
      'og:url': canonicalUrl,
      'og:image': image,
      'og:type': type,
      'og:site_name': siteConfig.name
    };

    Object.entries(ogTags).forEach(([property, content]) => {
      let ogTag = document.querySelector(`meta[property="${property}"]`);
      if (!ogTag) {
        ogTag = document.createElement('meta');
        ogTag.setAttribute('property', property);
        document.head.appendChild(ogTag);
      }
      ogTag.setAttribute('content', content);
    });

    // Update Twitter Card tags
    const twitterTags = {
      'twitter:card': 'summary_large_image',
      'twitter:title': fullTitle,
      'twitter:description': description,
      'twitter:image': image,
      'twitter:site': '@rust4noobs'
    };

    Object.entries(twitterTags).forEach(([name, content]) => {
      let twitterTag = document.querySelector(`meta[name="${name}"]`);
      if (!twitterTag) {
        twitterTag = document.createElement('meta');
        twitterTag.setAttribute('name', name);
        document.head.appendChild(twitterTag);
      }
      twitterTag.setAttribute('content', content);
    });

    // Update canonical URL
    let canonicalTag = document.querySelector('link[rel="canonical"]');
    if (!canonicalTag) {
      canonicalTag = document.createElement('link');
      canonicalTag.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalTag);
    }
    canonicalTag.setAttribute('href', canonicalUrl);
  }, [fullTitle, description, image, type, canonicalUrl, keywords]);

  return null;
}; 