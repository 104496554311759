/**
 * BattleMetrics API Service
 * Handles all interactions with the BattleMetrics API
 */

interface ServerPlayerHistoryPoint {
  type: string;
  attributes: {
    timestamp: string;
    value: number;
    max: number;
    min: number;
  };
}

interface ServerResponse {
  data: {
    type: string;
    id: string;
    attributes: {
      id: string;
      name: string;
      address: string | null;
      ip: string;
      port: number;
      players: number;
      maxPlayers: number;
      rank: number;
      location: [number, number];
      status: string;
      details: {
        tags: string[];
        official: boolean;
        rust_type: string;
        map: string;
        environment: string;
        rust_build: string;
        rust_ent_cnt_i: number;
        rust_fps: number;
        rust_fps_avg: number;
        rust_gc_cl: number;
        rust_gc_mb: number;
        rust_hash: string;
        rust_headerimage: string;
        rust_mem_pv: null | number;
        rust_mem_ws: null | number;
        pve: boolean;
        rust_uptime: number;
        rust_url: string;
        rust_world_seed: number;
        rust_world_size: number;
        rust_maps?: {
          seed: number;
          size: number;
          url: string;
          thumbnailUrl: string;
          monumentCount: number;
          barren: boolean;
          updatedAt: string;
          biomePercentages: Record<string, number>;
          islands: number;
          mountains: number;
          iceLakes: number;
          rivers: number;
          monumentCounts: Record<string, number>;
          monuments: string[];
        };
        rust_description: string;
        rust_modded: boolean;
        rust_queued_players: number;
        rust_gamemode: string;
        rust_born: string;
        rust_last_wipe: string;
        rust_last_wipe_ent: string | null;
        rust_settings_source: string;
        rust_settings: {
          upkeep: number;
          blueprints: boolean;
          forceWipeType: string;
          groupLimit: number;
          teamUILimit: number;
          kits: boolean;
          rates: {
            component: number;
            craft: number;
            gather: number;
            scrap: number;
          };
          wipes: any[];
          decay: number;
          timeZone: string;
          version: number;
        };
        rust_wipes: Array<{
          type: string;
          timestamp: string;
        }>;
        rust_next_wipe: string;
        rust_next_wipe_map: string;
        serverSteamId: string;
      };
      private: boolean;
      createdAt: string;
      updatedAt: string;
      portQuery: number;
      country: string;
      queryStatus: string;
    };
    relationships: {
      game: {
        data: {
          type: string;
          id: string;
        };
      };
    };
  };
  included?: Array<{
    id: string;
    type: string;
    attributes: {
      value: number;
    };
    relationships: {
      server: {
        data: {
          type: string;
          id: string;
        };
      };
    };
  }>;
}

interface ServerPlayerHistoryResponse {
  data: Array<{
    type: string;
    attributes: {
      timestamp: string;
      max: number;
      value: number;
      min: number;
    };
  }>;
}

// Cache storage for server details and player history
interface CacheEntry<T> {
  data: T;
  timestamp: number;
}

const cache = {
  serverDetails: new Map<string, CacheEntry<ServerResponse>>(),
  playerHistory: new Map<string, CacheEntry<ServerPlayerHistoryResponse>>()
};

const CACHE_DURATION = 60 * 1000; // 1 minute in milliseconds

interface RateLimitError {
  errors: Array<{
    code: string;
    title: string;
    detail: string;
    meta: {
      period: number;
      limit: number;
      used: number;
      available: number;
      allow: boolean;
      tryAgain: string;
    };
  }>;
}

/**
 * Calculates delay needed for rate limit in milliseconds
 * @param tryAgainTime ISO string of when to try again
 * @returns milliseconds to wait
 */
function calculateRateLimitDelay(tryAgainTime: string): number {
  const tryAgainDate = new Date(tryAgainTime);
  const now = new Date();
  return Math.max(0, tryAgainDate.getTime() - now.getTime());
}

/**
 * Handles API response, including rate limiting
 * @param response Fetch response object
 * @param endpoint Description of the endpoint for error messages
 * @returns Response JSON if successful
 */
async function handleApiResponse<T>(response: Response, endpoint: string): Promise<T> {
  if (response.status === 429) {
    const errorData = await response.json() as RateLimitError;
    const rateLimitInfo = errorData.errors[0];
    const delay = calculateRateLimitDelay(rateLimitInfo.meta.tryAgain);
    
    console.warn(
      `Rate limited when fetching ${endpoint}. ` +
      `Used ${rateLimitInfo.meta.used}/${rateLimitInfo.meta.limit} requests in ${rateLimitInfo.meta.period}s. ` +
      `Waiting ${Math.ceil(delay / 1000)}s until ${rateLimitInfo.meta.tryAgain}`
    );
    
    await new Promise(resolve => setTimeout(resolve, delay));
    throw new Error('RATE_LIMITED');
  }

  if (!response.ok) {
    const errorText = await response.text();
    console.error(`${endpoint} API response:`, response.status, errorText);
    throw new Error(`Failed to fetch ${endpoint} (${response.status}): ${errorText}`);
  }

  const data = await response.json();
  return data;
}

/**
 * Makes an API request with caching and rate limit handling
 * @param url Request URL
 * @param apiKey BattleMetrics API key
 * @param cacheKey Key for caching
 * @param cacheMap Cache storage map
 * @param endpoint Description of the endpoint
 * @returns Response data
 */
async function makeApiRequest<T>(
  url: URL,
  apiKey: string,
  cacheKey: string,
  cacheMap: Map<string, CacheEntry<T>>,
  endpoint: string
): Promise<T> {
  // Check cache first
  const cached = cacheMap.get(cacheKey);
  if (cached && (Date.now() - cached.timestamp) < CACHE_DURATION) {
    return cached.data;
  }

  const maxRetries = 3;
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Accept': 'application/json'
        }
      });

      const data = await handleApiResponse<T>(response, endpoint);
      
      // Cache the successful response
      cacheMap.set(cacheKey, {
        data,
        timestamp: Date.now()
      });

      return data;
    } catch (error: unknown) {
      if (error instanceof Error && error.message === 'RATE_LIMITED' && retries < maxRetries - 1) {
        retries++;
        continue;
      }
      throw error;
    }
  }

  throw new Error(`Failed to fetch ${endpoint} after ${maxRetries} retries`);
}

/**
 * Batch cache for storing multiple server data
 */
interface BatchServerData {
  serverDetails: Map<string, ServerResponse>;
  playerHistories: Map<string, ServerPlayerHistoryResponse>;
  timestamp: number;
}

const batchCache = new Map<string, BatchServerData>();

/**
 * Fetches multiple server details in a single batch
 * @param serverIds Array of BattleMetrics server IDs
 * @returns Promise with map of server data
 */
export const fetchServersBatch = async (
  serverIds: string[]
): Promise<BatchServerData> => {
  // Generate cache key for this batch of servers
  const cacheKey = serverIds.sort().join(',');
  
  // Check batch cache first
  const cached = batchCache.get(cacheKey);
  if (cached && (Date.now() - cached.timestamp) < CACHE_DURATION) {
    return cached;
  }

  const apiKey = import.meta.env.VITE_BATTLEMETRICS_TOKEN;
  if (!apiKey) {
    throw new Error('BattleMetrics API key is missing');
  }

  // Fetch all server details and player histories in parallel
  const [serverDetails, playerHistories] = await Promise.all([
    Promise.all(
      serverIds.map(id => 
        makeApiRequest<ServerResponse>(
          new URL(`https://api.battlemetrics.com/servers/${id}?include=serverDescription,uptime:7`),
          apiKey,
          `${id}-details`,
          cache.serverDetails,
          'server details'
        )
      )
    ),
    Promise.all(
      serverIds.map(id => {
        const stopTime = new Date();
        const startTime = new Date();
        startTime.setDate(startTime.getDate() - 14); // Fetch 14 days for all servers

        const url = new URL(`https://api.battlemetrics.com/servers/${id}/player-count-history`);
        url.searchParams.append('start', startTime.toISOString());
        url.searchParams.append('stop', stopTime.toISOString());
        url.searchParams.append('resolution', '60'); // Request hourly data points

        return makeApiRequest<ServerPlayerHistoryResponse>(
          url,
          apiKey,
          `${id}-14`,
          cache.playerHistory,
          'player history'
        );
      })
    )
  ]);

  // Create maps for easy access
  const detailsMap = new Map(
    serverDetails.map((detail, index) => [serverIds[index], detail])
  );
  const historiesMap = new Map(
    playerHistories.map((history, index) => [serverIds[index], history])
  );

  const batchData: BatchServerData = {
    serverDetails: detailsMap,
    playerHistories: historiesMap,
    timestamp: Date.now()
  };

  // Cache the batch result
  batchCache.set(cacheKey, batchData);

  return batchData;
};

interface ServerDataResponse {
  data: ServerResponse['data'];
  included?: ServerResponse['included'];
  playerAverages: {
    sevenDayAvg: number;
    fourteenDayAvg: number;
  };
  playerHistory?: {
    data: ServerPlayerHistoryPoint[];
  };
}

/**
 * Get server details and player count averages in a single call
 * @param serverId BattleMetrics server ID
 * @returns Promise with server data and averages
 */
export const getServerData = async (serverId: string): Promise<ServerDataResponse> => {
  const batchData = await fetchServersBatch([serverId]);
  const serverDetails = batchData.serverDetails.get(serverId);
  const playerHistory = batchData.playerHistories.get(serverId);

  if (!serverDetails || !playerHistory) {
    throw new Error(`Failed to fetch data for server ${serverId}`);
  }

  // Sort data points by timestamp to ensure chronological order
  const sortedData = [...playerHistory.data].sort((a, b) => 
    new Date(a.attributes.timestamp).getTime() - new Date(b.attributes.timestamp).getTime()
  );

  const values = sortedData.map(point => point.attributes.value);
  
  // Calculate how many hourly data points we should have
  const sevenDayPoints = 7 * 24; // 7 days * 24 hours
  const fourteenDayPoints = 14 * 24; // 14 days * 24 hours

  // Get the most recent data points for each time period
  const sevenDayValues = values.slice(-sevenDayPoints);
  const fourteenDayValues = values.slice(-fourteenDayPoints);
  
  return {
    ...serverDetails,
    playerAverages: {
      sevenDayAvg: calculateAverage(sevenDayValues),
      fourteenDayAvg: calculateAverage(fourteenDayValues)
    },
    playerHistory: playerHistory
  };
};

/**
 * Get data for multiple servers in a single batch
 * @param serverIds Array of BattleMetrics server IDs
 * @returns Promise with map of server data
 */
export const getServersData = async (serverIds: string[]): Promise<ServerDataResponse[]> => {
  const batchData = await fetchServersBatch(serverIds);
  
  return serverIds.map(serverId => {
    const serverDetails = batchData.serverDetails.get(serverId);
    const playerHistory = batchData.playerHistories.get(serverId);

    if (!serverDetails?.data) {
      // Return a properly typed empty response with all required fields
      return {
        data: {
          type: 'server',
          id: serverId,
          attributes: {
            id: serverId,
            name: 'Unknown',
            address: null,
            ip: '',
            port: 0,
            players: 0,
            maxPlayers: 0,
            rank: 0,
            location: [0, 0],
            status: 'offline',
            details: {
              tags: [],
              official: false,
              rust_type: '',
              map: '',
              environment: '',
              rust_build: '',
              rust_ent_cnt_i: 0,
              rust_fps: 0,
              rust_fps_avg: 0,
              rust_gc_cl: 0,
              rust_gc_mb: 0,
              rust_hash: '',
              rust_headerimage: '',
              rust_mem_pv: null,
              rust_mem_ws: null,
              pve: false,
              rust_uptime: 0,
              rust_url: '',
              rust_world_seed: 0,
              rust_world_size: 0,
              rust_description: '',
              rust_modded: false,
              rust_queued_players: 0,
              rust_gamemode: '',
              rust_born: '',
              rust_last_wipe: '',
              rust_last_wipe_ent: null,
              rust_settings_source: '',
              rust_settings: {
                upkeep: 1,
                blueprints: true,
                forceWipeType: '',
                groupLimit: 0,
                teamUILimit: 0,
                kits: false,
                rates: {
                  component: 1,
                  craft: 1,
                  gather: 1,
                  scrap: 1
                },
                wipes: [],
                decay: 1,
                timeZone: '',
                version: 1
              },
              rust_wipes: [],
              rust_next_wipe: '',
              rust_next_wipe_map: '',
              serverSteamId: ''
            },
            private: false,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            portQuery: 0,
            country: '',
            queryStatus: 'offline'
          },
          relationships: {
            game: {
              data: {
                type: 'game',
                id: 'rust'
              }
            }
          }
        },
        playerAverages: {
          sevenDayAvg: 0,
          fourteenDayAvg: 0
        }
      };
    }

    if (!playerHistory?.data) {
      return {
        ...serverDetails,
        playerAverages: {
          sevenDayAvg: 0,
          fourteenDayAvg: 0
        }
      };
    }

    // Sort data points by timestamp to ensure chronological order
    const sortedData = [...playerHistory.data].sort((a, b) => 
      new Date(a.attributes.timestamp).getTime() - new Date(b.attributes.timestamp).getTime()
    );

    const values = sortedData.map(point => point.attributes.value);
    
    const sevenDayPoints = 7 * 24;
    const fourteenDayPoints = 14 * 24;

    const sevenDayValues = values.slice(-sevenDayPoints);
    const fourteenDayValues = values.slice(-fourteenDayPoints);

    return {
      ...serverDetails,
      playerAverages: {
        sevenDayAvg: sevenDayValues.reduce((sum, val) => sum + val, 0) / sevenDayValues.length,
        fourteenDayAvg: fourteenDayValues.reduce((sum, val) => sum + val, 0) / fourteenDayValues.length
      }
    };
  });
};

/**
 * Calculates average from an array of player count values
 * @param values Array of player count values
 * @returns Average value
 */
function calculateAverage(values: number[]): number {
  if (values.length === 0) return 0;
  const sum = values.reduce((acc, val) => acc + val, 0);
  return Math.round(sum / values.length);
}

// Deprecate these individual functions in favor of batch operations
/** @deprecated Use getServerData or getServersData instead */
export const fetchServerDetails = async (serverId: string): Promise<ServerResponse> => {
  const data = await getServerData(serverId);
  return {
    data: data.data,
    included: data.included
  };
};

/** @deprecated Use getServerData or getServersData instead */
export const getPlayerCountAverages = async (serverId: string): Promise<{sevenDayAvg: number, fourteenDayAvg: number}> => {
  const data = await getServerData(serverId);
  return data.playerAverages;
};

/** @deprecated Use getServerData or getServersData instead */
export const fetchPlayerHistory = async (serverId: string): Promise<ServerPlayerHistoryResponse> => {
  const batchData = await fetchServersBatch([serverId]);
  const history = batchData.playerHistories.get(serverId);
  if (!history) {
    throw new Error(`Failed to fetch player history for server ${serverId}`);
  }
  return history;
};

/** @deprecated Use getServerData or getServersData instead */
export const getAveragePlayerCount = async (serverId: string, days: number): Promise<number> => {
  const data = await getServerData(serverId);
  return days === 7 ? data.playerAverages.sevenDayAvg : data.playerAverages.fourteenDayAvg;
}; 