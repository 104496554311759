import { useEffect } from 'react';
import { siteConfig } from '../config/site.config';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

export const GoogleAnalytics = () => {
  useEffect(() => {
    // Only load GA in production and if tracking ID is configured
    if (process.env.NODE_ENV === 'production' && siteConfig.analytics?.googleAnalyticsId) {
      // Create script element
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${siteConfig.analytics.googleAnalyticsId}`;
      script.async = true;
      
      // Add the script to the document
      document.head.appendChild(script);
      
      // Initialize gtag
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: any[]) {
        window.dataLayer.push(args);
      }
      gtag('js', new Date());
      gtag('config', siteConfig.analytics.googleAnalyticsId);
      
      // Cleanup
      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);
  
  return null;
}; 