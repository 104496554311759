import type { ButtonHTMLAttributes, ReactNode } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline';
  size?: 'sm' | 'md' | 'lg';
  children: ReactNode;
}

/**
 * A customizable button component that supports different variants and sizes.
 * @param variant - The visual style of the button ('primary', 'secondary', or 'outline')
 * @param size - The size of the button ('sm', 'md', or 'lg')
 * @param children - The content to be displayed inside the button
 * @param props - Additional HTML button attributes
 */
export const Button = ({
  variant = 'primary',
  size = 'md',
  children,
  className = '',
  ...props
}: ButtonProps) => {
  const baseStyles = 'inline-flex items-center justify-center rounded-lg font-semibold transition-all duration-300';
  
  const variants = {
    primary: 'bg-rust-red hover:bg-rust-orange text-white',
    secondary: 'bg-zinc-700 hover:bg-zinc-600 text-white',
    outline: 'border-2 border-rust-red hover:bg-rust-red/10 text-rust-red'
  };
  
  const sizes = {
    sm: 'px-4 py-2 text-sm',
    md: 'px-6 py-3 text-base',
    lg: 'px-8 py-4 text-lg'
  };

  const classes = [
    baseStyles,
    variants[variant],
    sizes[size],
    className
  ].join(' ');
  
  return (
    <button
      className={classes}
      {...props}
    >
      {children}
    </button>
  );
}; 