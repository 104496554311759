import { useState, useEffect, useRef } from 'react';
import { Button } from './ui/Button';
import { siteConfig } from '../config/site.config';
import { useActiveSection } from '../hooks/useActiveSection';
import { Link, useLocation } from 'react-router-dom';
import { getDiscordInfo } from '../services/discord';
import { UsersIcon, CheckIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';
import { loginWithSteam } from '../services/steam';
import { track } from '@vercel/analytics';

// Navigation items for the home page
const homeNavItems = [
  { name: 'Home', href: '/' },
  { name: 'About', href: '#about' },
  { name: 'Features', href: '#features' },
  { name: 'Servers', href: '#servers' },
  { name: 'Leaderboards', href: '/leaderboards' },
  { name: 'Staff Team', href: '#staff' },
  { name: 'Store', href: '#store' },
];

// Navigation items for other pages
const defaultNavItems = [
  { name: 'Home', href: '/' },
  { name: 'Leaderboards', href: '/leaderboards' },
];

export const Navigation = () => {
  const activeSection = useActiveSection(['about', 'servers', 'features', 'staff', 'store']);
  const location = useLocation();
  const [discordMemberCount, setDiscordMemberCount] = useState<number | null>(null);
  const { user, isAuthenticated, logout } = useAuth();
  const [copiedSteamId, setCopiedSteamId] = useState(false);
  
  // Use home navigation items only when on the home page
  const navItems = location.pathname === '/' ? homeNavItems : defaultNavItems;

  // Mobile menu state
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef<HTMLDivElement>(null);
  
  // Dropdown menu state
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  
  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  
  // Handle navigation item click
  const handleNavItemClick = (e: React.MouseEvent, href: string, name: string) => {
    // If it's the home link and we're already on the home page, just scroll to top
    if (href === '/' && location.pathname === '/') {
      e.preventDefault();
      scrollToTop();
      return;
    }
    
    // For hash links on the current page, let the default behavior happen
    if (href.startsWith('#') && location.pathname === '/') {
      return;
    }
    
    // Track leaderboards click if applicable
    if (name === 'Leaderboards') {
      track('leaderboards_click', { 
        steamId: isAuthenticated && user ? user.steamid : 'not_logged_in' 
      });
    }
    
    // For other navigation, close the mobile menu
    setMobileMenuOpen(false);
  };
  
  // Close mobile menu when navigating
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname, location.hash]);
  
  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target as Node)) {
        setMobileMenuOpen(false);
      }
    };

    if (mobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      // Prevent scrolling when mobile menu is open
      document.body.style.overflow = 'hidden';
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = '';
    };
  }, [mobileMenuOpen]);
  
  // Close the dropdown menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Fetch Discord member count
  useEffect(() => {
    const fetchDiscordInfo = async () => {
      try {
        const discordInfo = await getDiscordInfo();
        if (discordInfo) {
          setDiscordMemberCount(discordInfo.approximate_member_count);
        }
      } catch (error) {
        console.error('Failed to fetch Discord info:', error);
      }
    };

    fetchDiscordInfo();
    
    // Refresh every 5 minutes
    const interval = setInterval(fetchDiscordInfo, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const getNavItemClasses = (href: string) => {
    const isHash = href.startsWith('#');
    const isHome = href === '/';
    let isActive = false;

    if (isHash) {
      // For hash links, check if we're on home page and the hash matches active section
      isActive = location.pathname === '/' && (
        href === '#' ? activeSection === '' : href === `#${activeSection}`
      );
    } else {
      // For regular routes, check if current path matches the href
      isActive = isHome ? location.pathname === '/' && !activeSection : location.pathname === href;
    }
    
    return `relative text-zinc-300 hover:text-white transition-colors ${
      isActive ? 'text-white' : ''
    } group`;
  };

  const getMobileNavItemClasses = (href: string) => {
    const isHash = href.startsWith('#');
    const isHome = href === '/';
    let isActive = false;

    if (isHash) {
      // For hash links, check if we're on home page and the hash matches active section
      isActive = location.pathname === '/' && (
        href === '#' ? activeSection === '' : href === `#${activeSection}`
      );
    } else {
      // For regular routes, check if current path matches the href
      isActive = isHome ? location.pathname === '/' && !activeSection : location.pathname === href;
    }
    
    return `block w-full text-left px-4 py-3 text-base ${
      isActive ? 'text-white bg-zinc-700' : 'text-zinc-300'
    } hover:bg-zinc-700 hover:text-white transition-colors`;
  };

  const getUnderlineStyle = (href: string) => {
    const isHash = href.startsWith('#');
    const isHome = href === '/';
    let isActive = false;

    if (isHash) {
      isActive = location.pathname === '/' && (
        href === '#' ? activeSection === '' : href === `#${activeSection}`
      );
    } else {
      isActive = isHome ? location.pathname === '/' && !activeSection : location.pathname === href;
    }

    return {
      transform: isActive ? 'scaleX(1)' : 'scaleX(0)'
    };
  };

  // Format member count with commas
  const formatMemberCount = (count: number): string => {
    return count.toLocaleString();
  };

  // Handle Steam login
  const handleSteamLogin = (e: React.MouseEvent) => {
    e.preventDefault();
    loginWithSteam();
  };

  // Logout handler with tracking
  const handleLogout = () => {
    // Track logout event before logging out
    if (user) {
      track('logout', { steamId: user.steamid });
    }
    logout();
    setIsMenuOpen(false);
  };

  const handleCopySteamId = (steamId: string) => {
    navigator.clipboard.writeText(steamId);
    setCopiedSteamId(true);
    track('copy_steam_id', { steamId });
    setTimeout(() => setCopiedSteamId(false), 2000);
  };

  // Handle Discord invite click with tracking
  const handleDiscordClick = () => {
    track('discord_invite_click', {
      steamId: isAuthenticated && user ? user.steamid : 'not_logged_in'
    });
    window.open(siteConfig.discordInvite, '_blank');
  };

  // Handle Steam profile view with tracking
  const handleSteamProfileClick = (steamId: string) => {
    track('view_steam_profile', { steamId });
  };

  return (
    <>
      {/* Desktop Navigation - Hidden on mobile (sm:) screens */}
      <nav className="fixed top-0 w-full z-50 bg-zinc-900 border-b border-zinc-800 hidden sm:block">
        <div className="w-full max-w-7xl mx-auto px-4">
          <div className="flex items-center h-14">
            {/* Logo */}
            <Link to="/" className="flex-none" onClick={scrollToTop}>
              <span className="text-lg font-display font-bold text-rust-orange">
                Rust4Noobs
              </span>
            </Link>
            
            {/* Navigation items */}
            <div className="flex flex-1 items-center justify-end space-x-6 lg:space-x-8">
              {navItems.map((item) => (
                item.href.startsWith('#') ? (
                  <a
                    key={item.name}
                    href={item.href}
                    className={getNavItemClasses(item.href)}
                    onClick={(e) => handleNavItemClick(e, item.href, item.name)}
                  >
                    {item.name}
                    <span 
                      className="absolute -bottom-[1.5px] left-0 w-full h-[2px] bg-rust-orange scale-x-0 group-hover:scale-x-100 transition-transform duration-200 ease-out origin-center" 
                      style={getUnderlineStyle(item.href)}
                    />
                  </a>
                ) : (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={getNavItemClasses(item.href)}
                    onClick={(e) => handleNavItemClick(e, item.href, item.name)}
                  >
                    {item.name}
                    <span 
                      className="absolute -bottom-[1.5px] left-0 w-full h-[2px] bg-rust-orange scale-x-0 group-hover:scale-x-100 transition-transform duration-200 ease-out origin-center" 
                      style={getUnderlineStyle(item.href)}
                    />
                  </Link>
                )
              ))}
              
              {/* Discord Button - Responsive versions */}
              {/* Full button on large screens */}
              <Button
                variant="primary"
                size="sm"
                onClick={handleDiscordClick}
                className="animate-shake-subtle hover:animate-none hidden lg:flex items-center gap-2"
              >
                <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z"/>
                </svg>
                <span>Join Discord</span>
                {discordMemberCount && (
                  <span className="flex items-center gap-1 bg-white/20 text-white text-xs px-2 py-0.5 rounded-full">
                    <UsersIcon className="w-3 h-3" />
                    {formatMemberCount(discordMemberCount)}
                  </span>
                )}
              </Button>
              
              {/* Compact icon-only button for medium screens */}
              <Button
                variant="primary"
                size="sm"
                onClick={handleDiscordClick}
                className="animate-shake-subtle hover:animate-none sm:flex lg:hidden items-center justify-center p-2"
                title="Join Discord"
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z"/>
                </svg>
                {discordMemberCount && (
                  <span className="absolute -top-1 -right-1 flex items-center justify-center bg-white text-indigo-600 text-xs w-4 h-4 rounded-full font-bold">
                    {discordMemberCount > 999 ? '+' : formatMemberCount(discordMemberCount)}
                  </span>
                )}
              </Button>
              
              {/* Steam Login/User Profile with Dropdown Menu */}
              {isAuthenticated && user ? (
                <div className="relative" ref={menuRef}>
                  <button 
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    className="flex items-center gap-2 bg-zinc-800 hover:bg-zinc-700 transition-colors rounded-full p-1"
                  >
                    <img 
                      src={user.avatarmedium} 
                      alt={user.personaname} 
                      className="w-8 h-8 rounded-full border border-zinc-700"
                    />
                    {/* Hamburger icon for the menu */}
                    <div className="w-4 h-4 flex flex-col justify-center items-center gap-1 mr-1">
                      <span className={`w-3 h-0.5 bg-white transition-transform ${isMenuOpen ? 'rotate-45 translate-y-[3px]' : ''}`}></span>
                      <span className={`w-3 h-0.5 bg-white transition-opacity ${isMenuOpen ? 'opacity-0' : ''}`}></span>
                      <span className={`w-3 h-0.5 bg-white transition-transform ${isMenuOpen ? '-rotate-45 -translate-y-[3px]' : ''}`}></span>
                    </div>
                  </button>
                  
                  {/* Dropdown Menu */}
                  {isMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-zinc-800 border border-zinc-700 rounded-md shadow-lg overflow-hidden z-10">
                      <div className="px-4 py-3 border-b border-zinc-700">
                        <p className="text-sm text-white font-medium truncate mb-1">{user.personaname}</p>
                        <button
                          onClick={() => handleCopySteamId(user.steamid)}
                          className="flex items-center gap-1 text-xs text-gray-400 hover:text-white transition-colors group w-full text-left bg-transparent"
                        >
                          <span className="truncate">{user.steamid}</span>
                          {copiedSteamId ? (
                            <CheckIcon className="w-3 h-3 flex-shrink-0 text-green-500 animate-bounce" />
                          ) : (
                            <DocumentDuplicateIcon className="w-3 h-3 flex-shrink-0 sm:opacity-0 sm:group-hover:opacity-100 opacity-100 transition-opacity" />
                          )}
                        </button>
                      </div>
                      <div className="py-2">
                        <a 
                          href={user.profileurl} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          onClick={() => handleSteamProfileClick(user.steamid)}
                          className="block px-4 py-2 text-sm text-white hover:bg-zinc-700 transition-colors"
                        >
                          View Steam Profile
                        </a>
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-rust-orange hover:bg-opacity-80 bg-transparent transition-colors flex items-center"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                          </svg>
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {/* Full Steam login button on large screens */}
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={handleSteamLogin}
                    className="hidden lg:flex items-center gap-2"
                  >
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M11.979 0C5.678 0 .511 4.86.022 11.037l6.432 2.658c.545-.371 1.203-.59 1.912-.59.063 0 .125.004.188.006l2.861-4.142V8.91c0-2.495 2.028-4.524 4.524-4.524 2.494 0 4.524 2.031 4.524 4.527s-2.03 4.525-4.524 4.525h-.105l-4.076 2.911c0 .052.004.105.004.159 0 1.875-1.515 3.396-3.39 3.396-1.635 0-3.016-1.173-3.331-2.727L.436 15.27C1.862 20.307 6.486 24 11.979 24c6.627 0 11.999-5.373 11.999-12S18.605 0 11.979 0zM7.54 18.21l-1.473-.61c.262.543.714.999 1.314 1.25 1.297.539 2.793-.076 3.332-1.375.263-.63.264-1.319.005-1.949s-.75-1.121-1.377-1.383c-.624-.26-1.29-.249-1.878-.03l1.523.63c.956.4 1.409 1.5 1.009 2.455-.397.957-1.497 1.41-2.454 1.012H7.54zm11.415-9.303c0-1.662-1.353-3.015-3.015-3.015-1.665 0-3.015 1.353-3.015 3.015 0 1.665 1.35 3.015 3.015 3.015 1.663 0 3.015-1.35 3.015-3.015zm-5.273-.005c0-1.252 1.013-2.266 2.265-2.266 1.249 0 2.266 1.014 2.266 2.266 0 1.251-1.017 2.265-2.266 2.265-1.253 0-2.265-1.014-2.265-2.265z"/>
                    </svg>
                    <span>Login with Steam</span>
                  </Button>
                  
                  {/* Compact Steam login button for medium screens */}
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={handleSteamLogin}
                    className="sm:flex lg:hidden items-center justify-center p-2"
                    title="Login with Steam"
                  >
                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M11.979 0C5.678 0 .511 4.86.022 11.037l6.432 2.658c.545-.371 1.203-.59 1.912-.59.063 0 .125.004.188.006l2.861-4.142V8.91c0-2.495 2.028-4.524 4.524-4.524 2.494 0 4.524 2.031 4.524 4.527s-2.03 4.525-4.524 4.525h-.105l-4.076 2.911c0 .052.004.105.004.159 0 1.875-1.515 3.396-3.39 3.396-1.635 0-3.016-1.173-3.331-2.727L.436 15.27C1.862 20.307 6.486 24 11.979 24c6.627 0 11.999-5.373 11.999-12S18.605 0 11.979 0zM7.54 18.21l-1.473-.61c.262.543.714.999 1.314 1.25 1.297.539 2.793-.076 3.332-1.375.263-.63.264-1.319.005-1.949s-.75-1.121-1.377-1.383c-.624-.26-1.29-.249-1.878-.03l1.523.63c.956.4 1.409 1.5 1.009 2.455-.397.957-1.497 1.41-2.454 1.012H7.54zm11.415-9.303c0-1.662-1.353-3.015-3.015-3.015-1.665 0-3.015 1.353-3.015 3.015 0 1.665 1.35 3.015 3.015 3.015 1.663 0 3.015-1.35 3.015-3.015zm-5.273-.005c0-1.252 1.013-2.266 2.265-2.266 1.249 0 2.266 1.014 2.266 2.266 0 1.251-1.017 2.265-2.266 2.265-1.253 0-2.265-1.014-2.265-2.265z"/>
                    </svg>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation Bar - Visible only on mobile screens */}
      <nav className="fixed top-0 w-full z-50 bg-zinc-900 border-b border-zinc-800 sm:hidden">
        <div className="w-full px-4">
          <div className="flex items-center justify-between h-14">
            {/* Logo */}
            <Link to="/" className="flex-none" onClick={scrollToTop}>
              <span className="text-lg font-display font-bold text-rust-orange">
                Rust4Noobs
              </span>
            </Link>
            
            {/* Mobile menu button */}
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="p-2 rounded-md text-white hover:bg-zinc-800 focus:outline-none bg-transparent"
            >
              <div className="w-6 h-5 flex flex-col justify-between items-center">
                <span className={`w-full h-0.5 bg-white transition-transform ${mobileMenuOpen ? 'rotate-45 translate-y-[9px]' : ''}`}></span>
                <span className={`w-full h-0.5 bg-white transition-opacity ${mobileMenuOpen ? 'opacity-0' : ''}`}></span>
                <span className={`w-full h-0.5 bg-white transition-transform ${mobileMenuOpen ? '-rotate-45 -translate-y-[9px]' : ''}`}></span>
              </div>
            </button>
          </div>
        </div>
      </nav>
      
      {/* Mobile Menu - Slides in from the right when open */}
      <div 
        ref={mobileMenuRef}
        className={`fixed inset-0 z-50 sm:hidden transition-transform duration-300 ease-in-out ${
          mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setMobileMenuOpen(false)}></div>
        <div className="absolute right-0 top-0 h-full w-3/4 max-w-xs bg-zinc-900 border-l border-zinc-800 overflow-y-auto">
          <div className="p-4 flex justify-between items-center border-b border-zinc-800">
            <h2 className="text-lg font-bold text-white">Menu</h2>
            <button
              onClick={() => setMobileMenuOpen(false)}
              className="p-1 rounded-full text-white hover:bg-zinc-800 bg-transparent"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          {/* Mobile navigation items */}
          <div className="pt-2 pb-4">
            {navItems.map((item) => (
              item.href.startsWith('#') ? (
                <a
                  key={item.name}
                  href={item.href}
                  className={getMobileNavItemClasses(item.href)}
                  onClick={(e) => {
                    handleNavItemClick(e, item.href, item.name);
                    setMobileMenuOpen(false);
                  }}
                >
                  {item.name}
                </a>
              ) : (
                <Link
                  key={item.name}
                  to={item.href}
                  className={getMobileNavItemClasses(item.href)}
                  onClick={(e) => {
                    handleNavItemClick(e, item.href, item.name);
                    setMobileMenuOpen(false);
                  }}
                >
                  {item.name}
                </Link>
              )
            ))}
          </div>
          
          {/* Mobile user section */}
          <div className="p-4 border-t border-zinc-800">
            {isAuthenticated && user ? (
              <div className="space-y-3">
                <div className="flex items-center gap-3">
                  <img 
                    src={user.avatarmedium} 
                    alt={user.personaname} 
                    className="w-10 h-10 rounded-full border border-zinc-700"
                  />
                  <div>
                    <p className="text-sm font-medium text-white mb-1">{user.personaname}</p>
                    <button
                      onClick={() => handleCopySteamId(user.steamid)}
                      className="flex items-center gap-1 text-xs text-gray-400 hover:text-white transition-colors group w-full text-left bg-transparent"
                    >
                      <span className="truncate">{user.steamid}</span>
                      {copiedSteamId ? (
                        <CheckIcon className="w-3 h-3 flex-shrink-0 text-green-500 animate-bounce" />
                      ) : (
                        <DocumentDuplicateIcon className="w-3 h-3 flex-shrink-0 opacity-100 transition-opacity" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2 pt-2">
                  <a 
                    href={user.profileurl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    onClick={() => handleSteamProfileClick(user.steamid)}
                    className="text-center px-3 py-2 text-sm text-white bg-zinc-800 hover:bg-zinc-700 rounded-md transition-colors"
                  >
                    Steam Profile
                  </a>
                  <button
                    onClick={handleLogout}
                    className="px-3 py-2 text-sm text-white bg-rust-orange bg-opacity-80 hover:bg-opacity-100 rounded-md transition-colors"
                  >
                    Logout
                  </button>
                </div>
              </div>
            ) : (
              <button
                onClick={handleSteamLogin}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-zinc-800 hover:bg-zinc-700 text-white rounded-md transition-colors"
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M11.979 0C5.678 0 .511 4.86.022 11.037l6.432 2.658c.545-.371 1.203-.59 1.912-.59.063 0 .125.004.188.006l2.861-4.142V8.91c0-2.495 2.028-4.524 4.524-4.524 2.494 0 4.524 2.031 4.524 4.527s-2.03 4.525-4.524 4.525h-.105l-4.076 2.911c0 .052.004.105.004.159 0 1.875-1.515 3.396-3.39 3.396-1.635 0-3.016-1.173-3.331-2.727L.436 15.27C1.862 20.307 6.486 24 11.979 24c6.627 0 11.999-5.373 11.999-12S18.605 0 11.979 0zM7.54 18.21l-1.473-.61c.262.543.714.999 1.314 1.25 1.297.539 2.793-.076 3.332-1.375.263-.63.264-1.319.005-1.949s-.75-1.121-1.377-1.383c-.624-.26-1.29-.249-1.878-.03l1.523.63c.956.4 1.409 1.5 1.009 2.455-.397.957-1.497 1.41-2.454 1.012H7.54zm11.415-9.303c0-1.662-1.353-3.015-3.015-3.015-1.665 0-3.015 1.353-3.015 3.015 0 1.665 1.35 3.015 3.015 3.015 1.663 0 3.015-1.35 3.015-3.015zm-5.273-.005c0-1.252 1.013-2.266 2.265-2.266 1.249 0 2.266 1.014 2.266 2.266 0 1.251-1.017 2.265-2.266 2.265-1.253 0-2.265-1.014-2.265-2.265z"/>
                </svg>
                <span>Login with Steam</span>
              </button>
            )}
          </div>
          
          {/* Discord button on mobile */}
          <div className="p-4 border-t border-zinc-800">
            <button
              onClick={handleDiscordClick}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md transition-colors"
            >
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                <path d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z"/>
              </svg>
              <span>Join Discord</span>
              {discordMemberCount && (
                <span className="flex items-center gap-1 bg-white/20 text-white text-xs px-2 py-0.5 rounded-full">
                  {formatMemberCount(discordMemberCount)}
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}; 